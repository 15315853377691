<template>
  <div>
    <div class="row">
      <div class="col-xs-12 end-xs">
        <button class="primary small mobilefull mar-r5" @click="() => editing = {}">New Schedule</button>
      </div>
      <div class="col-xs-12">
        <table class="mobiletable invoices">
          <thead>
            <th>Client</th>
            <th>Reference</th>
            <th>Last Sent</th>
            <th>Next Send</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-if="!schedules.length">
              <td colspan="99" align="center">No Scheduled Invoices</td>
            </tr>
            <tr v-for="row in schedules" :key="row.id">
              <td>{{row.Client ? row.Client.name : ''}}</td>
              <td>{{row.reference}}</td>
              <td>{{$format.datetime(row.lastCreate)}}</td>
              <td>{{$format.datetime(row.nextCreate)}}</td>
              <td>
                <div class="quickFlex">
                  <button class="xsmall mar-r5" @click="() => editing = row">edit</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Drawer :isOpen="editing" :onClose="() => {}" :width="'500px'">
      <EditSchedule v-if="editing" :data="editing" @onFinish="getSchedules()" />
    </Drawer>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import EditSchedule from "./Schedule";
export default {
  components: { EditSchedule },
  data() {
    return {
      schedules: [],
      editing: undefined
    };
  },
  created() {
    this.getSchedules();
  },
  methods: {
    getSchedules() {
      this.editing = undefined;
      axios.get("scheduleinvoice").then(res => (this.schedules = res.data));
    }
  }
};
</script>

<style>
</style>