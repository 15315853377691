<template>
  <div>
    <div class="row">
      <div v-if="user.role === 'admin'" class="col-md-4 col-xs-12 form-group">
        <label>Client</label>
        <select v-model="client">
          <option :value="undefined">All</option>
          <option v-for="row of clients" :value="row.id">{{ row.name }}</option>
        </select>
      </div>
      <div v-if="user.role === 'admin'" class="col-md-4 col-xs-12 form-group">
        <label>Search</label>
        <input v-model="searchText" placeholder="Search by Name" />
      </div>
      <div v-if="user.role === 'admin'" class="col-xs-12 col-md-4 end-xs">
        <button class="primary small mobilefull" @click="createProject()">New Project</button>
      </div>
      <div class="col-xs-12">
        <table class="mobiletable projects">
          <thead>
            <th>Name</th>
            <th>Client</th>
            <th>Tasks</th>
            <th style="width: 100px; text-align: center">Status</th>
            <th class="fixed100"></th>
          </thead>
          <tbody>
            <tr v-for="project in filteredProjects" :key="project.id">
              <td>{{ project.name }}</td>
              <td>{{ project.Client ? project.Client.name : "" }}</td>
              <td>{{ project.completeTasks }} / {{ project.totalTasks }} Complete {{ format.percent(project.totalTasks, project.completeTasks) }}</td>
              <td class>
                <div class="chip" :class="statusColor(project)">{{ project.status }}</div>
              </td>
              <td>
                <div class="quickFlex">
                  <button class="xsmall secondary mar-r5" @click="$router.push('/projects/' + project.id)">view</button>
                  <button v-if="user.role === 'admin'" class="xsmall mar-r5" @click="editProject(project)">edit</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Drawer :isOpen="editingProject" :onClose="() => {}" :width="'500px'">
      <EditProject v-if="editingProject" :data="editingProject" :clients="clients" :onFinish="closeDrawer" />
    </Drawer>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import format from "../../utils/format";
import EditProject from "./EditProject";
export default {
  components: { EditProject },
  data() {
    return {
      format,
      user: JSON.parse(localStorage.getItem("user")),
      projects: [],
      clients: [],
      client: undefined,
      searchText: "",
      total: 0,
      editingProject: undefined,
    };
  },
  mounted() {
    this.getClients();
    this.getProjects();
  },
  methods: {
    createProject() {
      this.editingProject = {};
    },
    editProject(project) {
      this.editingProject = project;
    },
    closeDrawer() {
      this.editingProject = undefined;
      this.getProjects();
    },
    getClients() {
      if (this.user.role === "admin") axios.get("client?orderBy=name").then((response) => (this.clients = response.data));
    },
    getProjects() {
      axios.get("project").then((response) => {
        this.projects = response.data;
      });
    },
    statusColor(project) {
      if (project.status === "Proposal") return "green";
      if (project.status === "Pending") return "grey";
      if (project.status === "Development") return "secondary";
      if (project.status === "Testing") return "blue";
      if (project.status === "Deployment") return "primary";
      if (project.status === "Live") return "primary";
      if (project.status === "Cancelled") return "grey";
    },
  },
  computed: {
    filteredProjects() {
      let arr = this.projects;
      if (this.client) {
        arr = arr.filter((p) => p.ClientId === this.client);
      }
      if (this.searchText) {
        arr = arr.filter((p) => p.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1);
      }
      return arr;
    },
  },
};
</script>

<style>
</style>