<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1>Pay Invoice</h1>
        <h2>Remaining Balance: {{$format.money(invoice.remainingBalance)}}</h2>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Amount</label>
        <input v-model="amount" type="number" :max="invoice.remainingBalance" min="0" />
      </div>
      <div class="col-xs-6">
        <button class="primary full-width mobilesmall" @click="save()">Pay</button>
      </div>
      <div class="col-xs-6">
        <button class="full-width mobilesmall" @click="$emit('onFinish')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
export default {
  props: ["invoice"],
  data() {
    return {
      amount: this.invoice.remainingBalance
    };
  },
  methods: {
    save() {
      var obj = {
        remainingBalance: this.invoice.remainingBalance - this.amount
      };
      if (obj.remainingBalance < 0) return;
      if (obj.remainingBalance === 0) {
        obj.status = "paid";
      }
      axios.put("invoice/" + this.invoice.id, obj).then(() => this.$emit("onFinish"));
    }
  }
};
</script>

<style>
</style>