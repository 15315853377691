<script>
import { Line } from "vue-chartjs";
import format from "../utils/format";
export default {
  extends: Line,
  props: ["chartdata"],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: "#CDD7D6"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: "#CDD7D6",
                autoSkipPadding: 50,
                callback: function(value, index, values) {
                  return format.money(value, undefined, 0);
                }
              }
            }
          ]
        }
      }
    };
  },
  mounted() {
    this.renderChart(this.chartdata, this.options || {});
  },
  watch: {
    chartdata() {
      this.$data._chart.update();
    }
  }
};
</script>

<style>
</style>