<template>
  <div class="mobileNav showMob" :class="{open}">
    <div class="row">
      <div class="col-xs-12 end-xs">
        <div class="close" @click="() => $emit('close')">
          <i class="far fa-times"></i>
        </div>
      </div>
      <div class="col-xs-12" style="height: calc(100vh - 82px)">
        <div v-if="isAdmin" class="navitem" @click="() => $router.push('/')">
          <div>Home</div>
          <i class="far fa-arrow-right"></i>
        </div>
        <div v-if="isAdmin" class="navitem" @click="() => $router.push('/clients')">
          <div>Clients</div>
          <i class="far fa-arrow-right"></i>
        </div>
        <div class="navitem" @click="() => $router.push('/projects')">
          <div>Projects</div>
          <i class="far fa-arrow-right"></i>
        </div>
        <div v-if="isAdmin" class="navitem" @click="() => $router.push('/calendar')">
          <div>Calendar</div>
          <i class="far fa-arrow-right"></i>
        </div>
        <div v-if="isAdmin" class="navitem" @click="() => $router.push('/bankaccounts')">
          <div>Accounts</div>
          <i class="far fa-arrow-right"></i>
        </div>
        <div v-if="isAdmin" class="navitem" @click="() => $router.push('/transactions')">
          <div>Transactions</div>
          <i class="far fa-arrow-right"></i>
        </div>
        <div v-if="isAdmin" class="navitem" @click="() => $router.push('/invoices')">
          <div>Invoices</div>
          <i class="far fa-arrow-right"></i>
        </div>
        <div class="navitem end" @click="logout()">Log Out</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["open"],
  data() {
    return {
      route: "/"
    };
  },
  methods: {
    isroute(name, exact) {
      return exact ? this.route === name : this.route.indexOf(name) > -1;
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.user && this.$store.state.user.role === "admin";
    }
  },
  watch: {
    "$route.path": function(newPath) {
      setTimeout(() => {
        this.route = newPath;
      }, 1000);
    }
  }
};
</script>

<style>
</style>