<template>
  <div>
    <div class="row">
      <div class="col-xs-6 form-group">
        <label for>Start Date</label>
        <datepicker v-model="startDate" :disabled="loading" wrapper-class="calendarwrapper" calendar-class="calendarclass" @closed="getTransactions()" input-class="white"></datepicker>
      </div>
      <div class="col-xs-6 form-group">
        <label for>End Date</label>
        <datepicker v-model="endDate" :disabled="loading" wrapper-class="calendarwrapper" calendar-class="calendarclass" @closed="getTransactions()" input-class="white"></datepicker>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Source</label>
        <select v-model="selectedSource" @change="getTransactions()" :disabled="loading">
          <option :value="account" v-for="account in accounts" :key="'account' + account.id">{{account.ProviderInstance.name}} - {{account.name}}</option>
          <option :value="card" v-for="card in cards" :key="'card' + card.id">{{card.ProviderInstance.name}} - {{card.name}}</option>
        </select>
      </div>
      <div class="col-xs-12 center-xs textWhite textXxl" v-show="loading">
        <i class="fad fa-spinner-third fa-spin"></i>
      </div>
      <div v-if="transactions && !loading" class="col-xs-12 form-group">
        <label for>Transaction</label>
        <select v-model="transaction">
          <option v-for="row in transactions" :key="row.id" :value="row">{{$format.money(row.amount)}} {{row.description}}</option>
        </select>
      </div>
      <div class="col-xs-12 form-group" v-if="transaction">
        <label for>Type</label>
        <select v-model="type">
          <option value>--Select--</option>
          <option value="sale">Sale</option>
          <option value="salary">Salary</option>
          <option value="hosting">Hosting</option>
          <option value="equipment">Equipment</option>
          <option value="service">Service</option>
          <option value="bank fee">Bank Fee</option>
          <option value="insurance">Insurance</option>
          <option value="crypto">Crypto</option>
        </select>
      </div>
      <div class="col-xs-12 mar-b30">
        <input v-model="hide" type="checkbox" id="hide" />
        <label for="hide">Hide</label>
      </div>
      <div class="col-xs-6">
        <button class="primary full-width mobilesmall" :disabled="!transaction || loading" @click="save()">Save</button>
      </div>
      <div class="col-xs-6">
        <button class="full-width mobilesmall" @click="() => $emit('onFinish')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },
  props: ["cards", "accounts"],
  data() {
    return {
      loading: false,
      startDate: new Date(),
      endDate: new Date(),
      selectedSource: undefined,
      transactions: undefined,
      transaction: undefined,
      type: "",
      hide: false
    };
  },
  methods: {
    getTransactions() {
      if (!this.selectedSource) return;
      this.loading = true;
      var promise;
      var start = moment(this.startDate)
        .startOf("day")
        .toISOString();
      var end = moment(this.endDate).endOf("day");
      if (end > moment()) end = moment().subtract(5, "minutes");
      end = end.toISOString();
      if (this.selectedSource.network) {
        // pull from card
        promise = axios.get("card/" + this.selectedSource.id + "/transactions?from=" + start + "&to=" + end);
      } else {
        promise = axios.get("bankaccount/" + this.selectedSource.id + "/transactions?from=" + start + "&to=" + end);
      }
      promise.then(res => {
        this.transactions = res.data;
        this.loading = false;
      });
    },
    save() {
      axios
        .post("transaction", {
          amount: this.selectedSource.network ? 0 - this.transaction.amount : this.transaction.amount,
          from: this.transaction.description,
          transactionId: this.transaction.transaction_id,
          type: this.type,
          datetime: this.transaction.timestamp,
          account_id: this.selectedSource.account_id,
          BankProviderId: this.selectedSource.BankProviderId,
          ProviderInstanceId: this.selectedSource.ProviderInstanceId,
          BankAccountId: this.selectedSource.network ? undefined : this.selectedSource.id,
          CardId: this.selectedSource.network ? this.selectedSource.id : undefined,
          hide: this.hide
        })
        .then(() => {
          this.$emit("onFinish");
        });
    }
  }
};
</script>

<style>
</style>