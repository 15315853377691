<template>
  <div class="row">
    <div class="col-xs-12">
      <h1>{{user.id ? 'Update User' : 'Create User'}}</h1>
    </div>
    <div class="col-xs-12 form-group">
      <label for>Name</label>
      <input v-model="user.name" :disabled="!!user.id" placeholder="User Name" />
    </div>
    <div class="col-xs-12 form-group">
      <label for>Email</label>
      <input type="email" v-model="user.email" :disabled="!!user.id" placeholder="joe@bloggs.com" />
    </div>
    <div class="col-xs-12 form-group">
      <label for>Role</label>
      <select v-model="user.role">
        <option value="admin">Admin</option>
        <option value="user">User</option>
        <option value="client">Client</option>
        <option value="accountant">Accountant</option>
      </select>
    </div>
    <div class="col-xs-12 form-group" v-if="user.role === 'client'">
      <label for>Client</label>
      <select v-model="user.ClientId">
        <option value>--Select Client--</option>
        <option v-for="client in clients" :value="client.id" :key="client.id">{{client.name}}</option>
      </select>
    </div>

    <div :class="{'col-xs-6': !user.id, 'col-xs-4': user.id}">
      <button class="primary full-width mobilesmall" @click="save()">Save</button>
    </div>
    <div :class="{'col-xs-6': !user.id, 'col-xs-4': user.id}">
      <button class="full-width mobilesmall" @click="() => $emit('onFinish')">Cancel</button>
    </div>
    <div v-if="user.id" class="col-xs-4">
      <button class="secondary full-width mobilesmall" @click="deleteuser()">Delete</button>
    </div>
  </div>
</template>

<script>
import axios from "../../../utils/axios";
export default {
  props: ["data"],
  data() {
    return {
      user: { role: "admin" },
      clients: []
    };
  },
  created() {
    axios.get("client").then(res => (this.clients = res.data));
    if (this.data && this.data.id) {
      this.user = JSON.parse(JSON.stringify(this.data));
      this.user.role = this.user.AccountUser.role;
      this.user.ClientId = this.user.AccountUser.ClientId;
    }
  },
  methods: {
    save() {
      var promise;
      if (this.user.id) {
        promise = axios.put("user/" + this.user.id, this.user);
      } else {
        promise = axios.post("user", this.user);
      }
      promise.then(() => {
        this.$emit("onFinish");
      });
    },
    deleteuser() {
      axios.delete("user/" + this.user.id).then(() => {
        this.$emit("onFinish");
      });
    }
  }
};
</script>

<style>
</style>