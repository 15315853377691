<template>
  <div class="row">
    <div class="col-xs-12">
      <h1>{{schedule.id ? 'Update' : 'Create'}} Schedule</h1>
    </div>
    <div class="col-xs-12 form-group">
      <label for>Client</label>
      <select v-model="schedule.ClientId">
        <option :value="undefined">--Select--</option>
        <option v-for="client in clients" :value="client.id">{{client.name}}</option>
      </select>
    </div>
    <div class="col-xs-12 form-group">
      <label>Reference</label>
      <input v-model="schedule.reference" placeholder="Reference on Invoice" />
    </div>
    <div class="col-xs-12 form-group noMarg">
      <label for>Send Interval</label>
    </div>
    <div class="col-xs-6 form-group">
      <input v-model="schedule.interval_count" type="number" min="1" />
    </div>
    <div class="col-xs-6 form-group">
      <select v-model="schedule.interval">
        <option value="week">Week</option>
        <option value="month">Month</option>
      </select>
    </div>
    <div class="col-xs-12 form-group noMarg">
      <label for>Next Send</label>
    </div>
    <div class="col-xs-6 form-group">
      <datepicker v-model="schedule.nextCreate" wrapper-class="calendarwrapper" calendar-class="calendarclass" input-class="white"></datepicker>
    </div>
    <div class="col-xs-6 form-group">
      <input placeholder="hh:mm" v-model="schedule.time" />
    </div>
    <div class="col-xs-12 form-group">
      <label for>Template</label>
      <select v-model="schedule.TemplateId">
        <option v-for="template in templates" :value="template.id">{{template.name}}</option>
      </select>
    </div>
    <div class="col-xs-6">
      <button class="small primary full-width" @click="save()">Save</button>
    </div>
    <div class="col-xs-6">
      <button class="small grey full-width" @click="$emit('onFinish')">Cancel</button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "@/utils/axios";
export default {
  components: { Datepicker },
  props: ["data"],
  data() {
    return {
      schedule: JSON.parse(JSON.stringify(this.data)),
      clients: [],
      templates: []
    };
  },
  created() {
    if (!this.schedule.interval) this.schedule.interval = "month";
    if (!this.schedule.interval_count) this.schedule.interval_count = 1;
    if (!this.schedule.time) this.schedule.time = "12:00";
    if (!this.schedule.nextCreate) this.schedule.nextCreate = new Date();
    axios.get("client").then(res => (this.clients = res.data));
    axios.get("template").then(res => {
      this.templates = res.data;
      if (!this.schedule.TemplateId) this.schedule.TemplateId = res.data[0].id;
    });
  },
  methods: {
    save() {
      let promise;
      if (this.schedule.id) {
        promise = axios.put("scheduleinvoice/" + this.schedule.id, this.schedule);
      } else {
        promise = axios.post("scheduleinvoice", this.schedule);
      }
      promise.then(() => this.$emit("onFinish"));
    }
  }
};
</script>

<style>
</style>