<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ entry.id ? "Update" : "Create" }} Entry</h1>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Client</label>
        <select v-model="entry.ClientId" class="white" @change="checkClient()">
          <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
        </select>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Project</label>
        <select v-model="entry.ProjectId" class="white">
          <option v-for="project in filteredProjects" :key="project.id" :value="project.id">{{ project.name }}</option>
        </select>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Days</label>
        <input class="white" type="number" v-model="entry.days" step="0.5" @change="updateCost()" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Cost</label>
        <input class="white" type="number" v-model="cost" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Repeat</label>
        <select v-model="repeat">
          <option value>Don't Repeat</option>
          <option value="week">Weekly</option>
          <option value="month">Monthly</option>
        </select>
      </div>
      <div v-if="repeat === 'week'" class="col-xs-12 mar-b30">
        <input v-model="repeatOption.monday" type="checkbox" id="monday" />
        <label for="monday">Monday</label>
        <input v-model="repeatOption.tuesday" type="checkbox" id="tueaday" />
        <label for="tueaday">Tuesday</label>
        <input v-model="repeatOption.wednesday" type="checkbox" id="wednesday" />
        <label for="wednesday">Wednesday</label>
        <input v-model="repeatOption.thursday" type="checkbox" id="thursday" />
        <label for="thursday">Thursday</label>
        <input v-model="repeatOption.friday" type="checkbox" id="friday" />
        <label for="friday">Friday</label>
        <input v-model="repeatOption.saturday" type="checkbox" id="saturday" />
        <label for="saturday">Saturday</label>
        <input v-model="repeatOption.sunday" type="checkbox" id="sunday" />
        <label for="sunday">Sunday</label>
      </div>
      <div v-if="repeat === 'month'" class="col-xs-12 mar-b30">
        <span v-for="day in daysInMonth" :key="day.value">
          <input v-model="repeatOption[day.value]" type="checkbox" :id="day.value" />
          <label :for="day.value">{{ day.label }}</label>
        </span>
      </div>
      <div v-if="repeat" class="col-xs-12 form-group">
        <label for>Until</label>
        <datepicker :value="repeatEnd" wrapper-class="calendarwrapper" :use-utc="true" @selected="(date) => (repeatEnd = date)" calendar-class="calendarclass" input-class="white"></datepicker>
      </div>
      <div :class="{ 'col-xs-6': !entry.id, 'col-xs-4': entry.id }">
        <button class="primary full-width mobilesmall" @click="save()">Save</button>
      </div>
      <div :class="{ 'col-xs-6': !entry.id, 'col-xs-4': entry.id }">
        <button class="full-width mobilesmall" @click="onFinish()">Cancel</button>
      </div>
      <div v-if="entry.id" class="col-xs-4">
        <button class="secondary full-width mobilesmall" @click="deleteEntry()">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: { Datepicker },
  props: ["data", "onFinish", "clients", "projects"],
  data() {
    return {
      entry: {},
      repeat: "",
      cost: 0,
      repeatOption: {},
      repeatEnd: undefined,
    };
  },
  mounted() {
    if (this.data) {
      this.entry = JSON.parse(JSON.stringify(this.data));
      this.updateCost();
    }
  },
  methods: {
    deleteEntry() {
      axios.delete("calendarentry/" + this.entry.id).then(() => this.$emit("deleted", this.entry.id));
    },
    checkClient() {
      var projects = this.filteredProjects;
      if (projects && projects.length) {
        if (!this.entry.ProjectId) {
          this.entry.ProjectId = projects[0].id;
        } else {
          var oldProject = this.projects.find((p) => p.id === this.entry.ProjectId);
          if (!oldProject || oldProject.ClientId !== this.entry.ClientId) this.entry.ProjectId = projects[0].id;
        }
      }
      this.updateCost();
    },
    updateCost() {
      if (!this.entry.ClientId) return;
      var client = this.clients.find((c) => c.id === this.entry.ClientId);
      if (!client) return;
      this.cost = this.entry.days * client.dailyRate;
      this.entry.cost = this.cost;
    },
    save() {
      var promise;
      this.entry.repeat = this.repeat;
      this.entry.repeatOption = this.repeatOption;
      this.entry.repeatEnd = this.repeatEnd;
      this.entry.cost = this.cost;
      if (this.entry.id) {
        promise = axios.put("calendarentry/" + this.entry.id, this.entry);
      } else {
        promise = axios.post("calendarentry", this.entry);
      }
      promise.then(this.onFinish);
    },
  },
  computed: {
    filteredProjects() {
      if (this.entry.ClientId) {
        return this.projects.filter((p) => p.ClientId === this.entry.ClientId);
      } else {
        return this.projects;
      }
    },
    daysInMonth() {
      var arr = [];
      var start = moment("01/01", "DD/MM");
      for (let i = 0; i < 31; i++) {
        arr.push({ label: start.format("Do"), value: i + 1 });
        start.add(1, "day");
      }
      return arr;
    },
  },
};
</script>

<style>
</style>