<template>
  <svg version="1.1" class="v-icon-holder" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 640" width="645" height="645" :style="this.svgstyle">
    <defs>
      <path d="M71.11 0L320 527.06L568.89 0L640 75.29L320 640L0 75.29L71.11 0Z" id="f6y27czFDk" />
    </defs>
    <g>
      <g>
        <use class="v-icon" xlink:href="#f6y27czFDk" opacity="1" fill="#4f4f97" fill-opacity="1" />
        <g>
          <use xlink:href="#f6y27czFDk" opacity="1" fill-opacity="0" stroke="#000000" stroke-width="0" stroke-opacity="1" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["svgstyle"]
};
</script>

<style>
</style>