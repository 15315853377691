<template>
  <div>
    <div v-if="user">
      <h1 class="textWhite mar-t30">User Details</h1>
      <div class="row">
        <div class="col-xs-12 col-md-4 col-md-offset-4">
          <div class="form-group">
            <label for>Name</label>
            <input class="white" v-model="user.name" />
          </div>
          <div class="form-group">
            <label for>Email</label>
            <input type="email" class="white" v-model="user.email" />
          </div>
          <div class="form-group">
            <label for>Password</label>
            <input type="password" class="white" v-model="user.password" />
          </div>
          <div class="centerCenter">
            <button :disabled="user.password && user.password !== '' && user.password.length < 5" class="primary small min-width" @click="save()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../utils/axios";
export default {
  data() {
    return {
      user: undefined
    };
  },
  created() {
    this.getMe();
  },
  methods: {
    getMe() {
      axios.get("user/me").then(res => (this.user = res.data));
    },
    save() {
      if (this.user.password && this.user.password.length < 5) {
        return;
      }

      axios.put("user/me", this.user).then(res => {
        this.$store.commit("setUser", res.data);
        this.$router.push("/");
      });
    }
  }
};
</script>

<style>
</style>