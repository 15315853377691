<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1>{{document.id ? 'Update' : 'Create'}} Document</h1>
      </div>
      <div v-if="taskId && documents.length" class="col-xs-12 form-group">
        <label for>Assign Others</label>
        <select v-model="otherDoc" class="white">
          <option value>--Select From Project--</option>
          <option v-for="doc in documents" :value="doc.id" :key="doc.id">{{doc.name}}</option>
        </select>
      </div>
      <div v-if="!otherDoc" class="col-xs-12 form-group">
        <label for>Name</label>
        <input type="text" v-model="document.name" class="white" />
      </div>
      <div v-if="!otherDoc" class="col-xs-12 form-group">
        <label for>Link</label>
        <input :disabled="otherDoc" type="text" v-model="document.link" class="white" @change="detectType()" />
      </div>
      <div v-if="!otherDoc" class="col-xs-12 form-group">
        <label for>Type</label>
        <select :disabled="otherDoc" v-model="document.type" class="white">
          <option value="doc">Document</option>
          <option value="xls">Speadsheet</option>
          <option value="api">API Docs</option>
          <option value="pdf">PDF</option>
          <option value="img">Image</option>
          <option value="vid">Video</option>
          <option value="design">Prototype</option>
          <option value="mp3">Audio</option>
          <option value="zip">Compressed</option>
          <option value>Other</option>
        </select>
      </div>
      <div :class="{'col-xs-6': !document.id, 'col-xs-4': document.id}">
        <button class="primary full-width" @click="save()">Save</button>
      </div>
      <div :class="{'col-xs-6': !document.id, 'col-xs-4': document.id}">
        <button class="full-width" @click="onFinish()">Cancel</button>
      </div>
      <div v-if="document.id" class="col-xs-4">
        <button class="secondary full-width" @click="deletedoc()">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
export default {
  props: ["data", "onFinish", "project", "taskId"],
  data() {
    return {
      document: {},
      otherDoc: undefined,
      documents: []
    };
  },
  mounted() {
    if (this.data) {
      this.document = JSON.parse(JSON.stringify(this.data));
      this.document.ClientId = this.project.ClientId;
    }
    this.getOthers();
  },
  methods: {
    detectType() {
      if (this.document.id) return;
      var name = this.document.link;
      if (!name || name.length < 5) return;
      var format = "";
      if (name.indexOf(".") > -1) {
        var split = name.split(".");
        var ext = split[split.length - 1].toLowerCase();
        if (ext === "doc" || ext === "docx") format = "doc";
        if (ext === "csv" || ext === "xlsx") format = "xls";
        if (ext === "pdf") format = "pdf";
        if (ext === "jpg" || ext === "png" || ext === "jpeg" || ext === "tiff" || ext === "gif" || ext === "webp") format = "img";
        if (ext === "mp4" || ext === "avi" || ext === "mov" || ext === "wmv" || ext === "mkv") format = "vid";
        if (ext === "mp3" || ext === "aiff" || ext === "wav") format = "mp3";
        if (ext === "ai") format = "design";
      }

      if (!format) {
        //   try and get the format based on the URL
        if (name.indexOf("/spreadsheet/") > -1) format = "xls";
        else if (name.indexOf("/document/") > -1) format = "doc";
        else if (name.indexOf("behance") > -1 || name.indexOf("adobe" > -1) || name.indexOf("figma") > -1 || name.indexOf("sketch") > -1 || name.indexOf("unsplash") > -1) format = "design";
      }

      if (format) {
        this.document.type = format;
      }
    },
    deletedoc() {
      axios.delete("project/" + this.project.id + "/document/" + this.document.id).then(this.onFinish);
    },
    getOthers() {
      if (this.taskId) axios.get("project/" + this.project.id + "/document").then(res => (this.documents = res.data));
    },
    save() {
      var promise;
      if (this.otherDoc) {
        promise = axios.put("project/" + this.project.id + "/document/" + this.otherDoc + "/assign", { taskId: this.taskId });
      } else {
        if (this.document.id) {
          promise = axios.put("project/" + this.project.id + "/document/" + this.document.id, this.document);
        } else {
          promise = axios.post("project/" + this.project.id + "/document", this.document);
        }
      }
      promise.then(this.onFinish);
    }
  }
};
</script>

<style>
</style>