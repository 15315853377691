<template>
  <div>
    <div class="row" v-if="clients.length">
      <div class="col-xs-12">
        <h1>{{ invoice.id ? "Update" : "Create" }} Invoice</h1>
      </div>
      <div class="col-xs-12 form-group" v-if="clients.length">
        <label for>Client</label>
        <select v-model="invoice.ClientId" class="white">
          <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
        </select>
      </div>
      <div class="col-xs-12 form-group noMarg">
        <label for>
          Items
          <button class="tiny mar-l10" @click="() => invoice.items.push({})">Add</button>
          <button class="tiny green mar-l10" @click="() => (importing = !importing)">Import</button>
        </label>
        <div class="row" v-if="importing">
          <div class="col-xs-4 form-group">
            <label for>From Date</label>
            <datepicker v-model="fromDate" wrapper-class="calendarwrapper" calendar-class="calendarclass" input-class="white"></datepicker>
          </div>
          <div class="col-xs-4 form-group">
            <label for>To Date</label>
            <datepicker v-model="toDate" wrapper-class="calendarwrapper" calendar-class="calendarclass" input-class="white"></datepicker>
          </div>
          <div class="col-xs-4">
            <button class="full-width tiny mar-t30" @click="importItems()">Import</button>
          </div>
        </div>
        <div v-for="(item, index) in invoice.items" class="row">
          <div class="col-xs-5 form-group">
            <input v-model="item.description" class="white" placeholder="Description" />
          </div>
          <div class="col-xs-3 form-group">
            <input type="number" v-model="item.days" step="0.5" class="white" placeholder="days" @change="calcPrice(index)" />
          </div>
          <div class="col-xs-3 form-group">
            <input type="number" v-model.number="item.price" class="white" placeholder="Price" />
          </div>
          <div class="col-xs-1 textLg clickable" @click="removeItem(index)">
            <div class="mar-t10">
              <i class="fad fa-times-circle"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Total</label>
        <input v-model="total" disabled class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Due Date</label>
        <datepicker v-model="invoice.dueDate" wrapper-class="calendarwrapper" calendar-class="calendarclass" input-class="white"></datepicker>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Reference</label>
        <input v-model="invoice.reference" placeholder="reference" class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Template</label>
        <select v-model="invoice.TemplateId">
          <option value>--Select Template--</option>
          <option v-for="template in templates" :value="template.id" :key="template.id">{{ template.name }}</option>
        </select>
      </div>
      <div class="col-xs-12 form-group"></div>
      <div class="col-xs-4">
        <button class="primary full-width small mobilesmall" :disabled="!invoice.ClientId || !invoice.TemplateId || !invoice.reference || !invoice.items.length || !invoice.dueDate" @click="save()">Save</button>
      </div>
      <div class="col-xs-4">
        <button class="full-width small mobilesmall" @click="$emit('onFinish')">Cancel</button>
      </div>
      <div class="col-xs-4">
        <button class="green full-width small mobilesmall" @click="saveAndSend()">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  props: ["data"],
  components: { Datepicker },
  data() {
    return {
      invoice: {
        ClientId: undefined,
        TemplateId: undefined,
        status: "draft",
        items: [],
        total: 0,
        reference: "",
        dueDate: moment().add(1, "week").toDate(),
      },
      importing: false,
      fromDate: moment().startOf("month").toDate(),
      toDate: new Date(),
      clients: [],
      templates: [],
    };
  },
  mounted() {
    this.getClients();
    this.getTemplates();
    if (this.data && this.data.id) {
      this.invoice = JSON.parse(JSON.stringify(this.data));
    }
  },
  methods: {
    getClients() {
      axios.get("client").then((res) => (this.clients = res.data));
    },
    getTemplates() {
      axios.get("template").then((res) => {
        this.templates = res.data;
        if (!this.invoice.TemplateId) this.invoice.TemplateId = res.data[0].id;
      });
    },
    removeItem(index) {
      this.invoice.items.splice(index, 1);
    },
    clean() {
      this.invoice.items = this.invoice.items.filter((i) => i.description && (i.price !== undefined || i.price > -1));
      this.invoice.total = this.total;
      this.invoice.remainingBalance = this.total;
    },
    importItems() {
      axios.get("calendarentry?startDate=" + moment(this.fromDate) + "&endDate=" + moment(this.toDate) + "&ClientId=" + this.invoice.ClientId).then((res) => {
        let items = [];
        res.data.forEach((row) => {
          var found = items.find((i) => i.description === row.Project.name);
          if (!found) {
            found = { description: row.Project.name, days: 0, price: 0 };
            items.push(found);
          }
          found.days += row.days;
        });
        this.invoice.items.push(...items);
        this.invoice.items.forEach((r, i) => this.calcPrice(i));
      });
    },
    calcPrice(index) {
      if (!this.invoice.ClientId) return;
      var invoice = this.invoice;
      var items = invoice.items;
      var item = items[index];
      var client = this.clients.find((c) => c.id == invoice.ClientId);
      item.price = client.dailyRate * parseFloat(item.days);
      this.$set(this.invoice.items, index, item);
    },
    save() {
      this.clean();
      var promise;
      if (!this.invoice.id) {
        promise = axios.post("invoice", this.invoice);
      } else {
        promise = axios.put("invoice/" + this.invoice.id, this.invoice);
      }

      promise.then(() => this.$emit("onFinish"));
    },
    saveAndSend() {
      this.clean();
      var promise;
      if (this.invoice.id) {
        promise = axios.post("invoice", this.invoice);
      } else {
        promise = axios.put("invoice/" + this.invoice.id, this.invoice);
      }
      promise.then(() => {
        axios.put("invoice/" + this.invoice.id + "/generate").then(() => this.$emit("onFinish"));
      });
    },
  },
  computed: {
    total() {
      var total = 0;
      if (!this.invoice.items) return total;
      this.invoice.items.forEach((i) => (total += i.price || 0));
      return total;
    },
  },
};
</script>

<style>
</style>