<template>
  <div class="panel bankaccount">
    <div class="syncing" :class="{ active: card.sync }" @click="synccard(index)" v-tooltip="card.sync ? 'Syncing Transactions' : 'Not Syncing Transactions'">
      <i class="fas fa-sync"></i>
    </div>

    <div class="notify" v-if="card.sync" :class="{ active: card.notify }" @click="notifycard(index)" v-tooltip="card.notify ? 'Notifications ON' : 'Notifications OFF'">
      <i class="far fa-bell"></i>
    </div>
    <div class="row center-xs" style="margin: auto 0">
      <div class="col-xs-12 textLg">{{ card.ProviderInstance.name }}</div>
      <div class="col-xs-12 mar-t20 mar-b20 quickFlex center">
        <img :src="card.logo" height="60" class="mar-r10" />
        <i class="card" :class="'fab fa-cc-' + card.network.toLowerCase()"></i>
      </div>
      <div class="col-xs-12 mar-b20 clickable" @click="$router.push('/bankaccounts/card/' + card.id + '/transactions')">{{ card.name }}</div>
      <div v-if="balance" class="col-xs-12 textMed noPad mar-b20">
        <div>{{ $format.money(balance.current) }}</div>
        <div class="textSml textDarkGrey">({{ $format.money(balance.available) }} Available)</div>
      </div>
      <div v-if="!balance && loaded" class="col-xs-12 textMed mar-b20"><button class="tiny" @click="resync()">Resync</button></div>
      <div v-show="!loaded" class="col-xs-2 textMed mar-b20 loadingicon">
        <i class="fad fa-spinner-third fa-spin"></i>
      </div>
      <div class="col-xs-12 textSml mar-b10">xxxx-xxxx-xxxx-{{ card.partialNumber }}</div>
      <div v-if="card.validFrom" class="col-xs-6 textSml mar-b10 end-xs">{{ card.validFrom }}</div>
      <div v-if="card.validTo" class="col-xs-6 textSml mar-b10 start-xs">{{ card.validTo }}</div>
      <div v-if="card.nameOnCard" class="col-xs-12 textSml">{{ card.nameOnCard }}</div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
export default {
  props: { card: Object, setCard: Function, balance: Object, loaded: Boolean },
  methods: {
    synccard() {
      axios
        .put("card/" + card.id, {
          sync: !card.sync,
        })
        .then((res) => {
          card.sync = res.data.sync;
          this.setCard(card);
        });
    },
    notifycard() {
      axios
        .put("card/" + card.id, {
          notify: !card.notify,
        })
        .then((res) => {
          card.notify = res.data.notify;
          setCard(card);
        });
    },
    resync() {
      window.location = "https://auth.truelayer.com/?response_type=code&client_id=endevrs-17b8f5&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=https://endevrs.com/callback&providers=uk-ob-" + this.card.ProviderInstance.name.toLowerCase() + "%20uk-oauth-all";
    },
  },
};
</script>

<style>
</style>