<template>
  <div class="loginContainer">
    <div v-if="!user && !error" style="width: 100vw">
      <div class="row">
        <div class="col-xs-12 center-xs textPrimary textXxl">
          <i class="fad fa-spinner-third fa-spin"></i>
        </div>
      </div>
    </div>
    <div class="row" style="width: 100vw" v-if="user || error">
      <div class="col-xs-12 col-md-4 col-md-offset-4 textWhite">
        <div v-if="user">
          <h1>You have been Invited to view {{account.name}}'s Endevrs Account</h1>
          <div v-if="user.password !== '--PLACEHOLDER--'">
            <div class="form-group">
              <label for>Name</label>
              <input v-model="name" placeholder="You Name Here" />
            </div>
            <div class="form-group">
              <label for>Password</label>
              <input type="password" v-model="password" placeholder="min 8 characters" />
            </div>
          </div>
          <div class="center-xs mar-t30">
            <button class="primary" @click="activate()">Accept Invitation</button>
          </div>
        </div>
        <div v-if="error" class="textWhite">
          <h1>There was a problem, please check your email</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "../utils/axios.js";
export default {
  data() {
    return {
      token: this.$route.query.token,
      user: undefined,
      account: undefined,
      error: false,
      name: "",
      password: ""
    };
  },
  created() {
    axios
      .get("activate?token=" + this.token)
      .then(res => {
        this.user = res.data.User;
        this.account = res.data.Account;
      })
      .catch(e => {
        this.error = true;
      });
  },
  methods: {
    activate() {
      axios
        .post("activate", {
          token: this.token,
          password: this.password,
          name: this.name
        })
        .then(res => {
          console.log(res.data);
          localStorage.setItem("token", res.data.token);
          this.$router.push("/");
        })
        .catch(() => {
          this.error = true;
        });
    }
  }
};
</script>