<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1>Create Timesheet</h1>
      </div>
      <div class="col-xs-12 form-group">
        <label for="">Client</label>
        <select v-model="clientId">
          <option :value="undefined">All Clients</option>
          <option v-for="client of clients" :value="client.id" :key="client.id">{{ client.name }}</option>
        </select>
      </div>
      <div class="col-xs-12 form-group">
        <label for="">Project</label>
        <select v-model="projectId">
          <option :value="undefined">All Projects</option>
          <option v-for="project of filteredProjects" :value="project.id" :key="project.id">{{ project.name }}</option>
        </select>
      </div>
      <div class="col-xs-12 form-group">
        <label for="">Start Date</label>
        <datepicker :value="startDate" wrapper-class="calendarwrapper" @selected="(date) => (startDate = date)" calendar-class="calendarclass" input-class="white"></datepicker>
      </div>
      <div class="col-xs-12 form-group">
        <label for="">End Date</label>
        <datepicker :value="endDate" wrapper-class="calendarwrapper" @selected="(date) => (endDate = date)" calendar-class="calendarclass" input-class="white"></datepicker>
      </div>
      <div class="col-xs-6">
        <button class="primary full-width mobilesmall" @click="create()">Create</button>
      </div>
      <div class="col-xs-6">
        <button class="full-width mobilesmall" @click="close()">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import download from "../../utils/download";
export default {
  props: ["close", "clients", "projects"],
  components: { Datepicker },
  data() {
    return {
      clientId: undefined,
      projectId: undefined,
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
    };
  },
  methods: {
    create() {
      let queryStr = "?startDate=" + moment(this.startDate).toISOString() + "&endDate=" + moment(this.endDate).toISOString();
      if (this.clientId) queryStr += "&clientId=" + this.clientId;
      if (this.projectId) queryStr += "&projectId=" + this.projectId;

      let name = "timesheet.csv";
      if (this.projectId) {
        let project = this.projects.find((p) => p.id === this.projectId);
        if (project) name = project.name + " timesheet.csv";
      } else if (this.clientId) {
        let client = this.clients.find((p) => p.id === this.clientId);
        if (client) name = client.name + " timesheet.csv";
      }

      download("calendarentry/timesheet" + queryStr, name, "text/csv");
      this.close();
    },
  },
  computed: {
    filteredProjects() {
      let arr = this.projects;
      if (this.clientId) arr = arr.filter((a) => a.ClientId === this.clientId);
      return arr;
    },
  },
};
</script>

<style>
</style>