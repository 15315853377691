export default {
    user: {
        authenticated: false,
        user: undefined
    },
    isAuthenticated() {
        // return true
        var token = localStorage.getItem("token");
        return !!token;
    },
    logout() {
        localStorage.removeItem("token");
        this.user.authenticated = false;
    }
};
