<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="mar-t5 quickFlex mar-b20 textHeavy" v-if="$store.state.user.role === 'admin'">
          <div class="textWhite mar-r10 min-width" v-if="!loading">Profit: {{ $format.money(profit) }}</div>
          <div class="textGreen mar-r10 min-width" v-if="!loading">Income: {{ $format.money(income) }}</div>
          <div class="textSecondary min-width" v-if="!loading">Expenses: {{ $format.money(Math.abs(expenses)) }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-2 mar-b10">
        <button v-if="$store.state.user.role === 'admin'" @click="() => (entry = {})" class="green small full-width">Manually Add</button>
      </div>
      <div class="col-xs-12 col-md-2 mar-b10">
        <button v-if="$store.state.user.role === 'admin'" @click="() => (importing = true)" class="primary small full-width">Import</button>
      </div>
      <div class="col-xs-12 col-md-2 mar-b10">
        <button @click="exportrows()" class="yellow small full-width">Export</button>
      </div>
      <div class="col-xs-12">
        <div class="row mar-t20">
          <div class="col-xs-12 col-md-2 form-group">
            <label for>From</label>
            <datepicker v-model="fromDate" :disabled="loading" wrapper-class="calendarwrapper" calendar-class="calendarclass" @closed="getTransactions()" input-class="white"></datepicker>
          </div>
          <div class="col-xs-12 col-md-2 form-group">
            <label for>To</label>
            <datepicker v-model="toDate" :disabled="loading" wrapper-class="calendarwrapper" calendar-class="calendarclass" @closed="getTransactions()" input-class="white"></datepicker>
          </div>
          <div class="col-xs-12 col-md-4 form-group">
            <label for>Description</label>
            <input v-model="filters.from" placeholder="search for the name" @change="filter()" />
          </div>
          <div class="col-xs-12 col-md-2 form-group">
            <label for>Type</label>
            <select v-model="filters.type" @change="filter()">
              <option value>All</option>
              <option value="sale">Sale</option>
              <option value="salary">Salary</option>
              <option value="hosting">Hosting</option>
              <option value="equipment">Equipment</option>
              <option value="service">Service</option>
              <option value="bank fee">Bank Fee</option>
              <option value="insurance">Insurance</option>
            </select>
          </div>
          <div class="col-xs-12 col-md-2 form-group">
            <label for>Direction</label>
            <select v-model="filters.direction" @change="filter()">
              <option value>All</option>
              <option value="inbound">Inbound</option>
              <option value="outbound">Outbound</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <table class="mobiletable transactiontable">
          <thead>
            <th style="width: 50px"></th>
            <th style="text-align: center">Date Time</th>
            <th>From</th>
            <th style="text-align: center">Value</th>
            <th style="text-align: center">Type</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-if="!loading && !transactions.length">
              <td colspan="99" align="center">No Transactions Found</td>
            </tr>

            <tr v-if="loading">
              <td colspan="99" align="center" class="textXl">
                <i class="fas fa-spinner-third fa-spin"></i>
              </td>
            </tr>
            <tr v-else v-for="row in transactions" :key="row.id">
              <td align="center">
                <img v-if="(row.BankAccount && row.BankAccount.logo) || (row.Card && row.Card.logo)" :src="row.BankAccount ? row.BankAccount.logo : row.Card.logo" height="12" />
                <i v-if="row.Card" class="textMg textPrimary mar-l10" :class="'fab fa-cc-' + row.Card.network.toLowerCase()"></i>
              </td>
              <td align="center">{{ format.datetime(row.datetime) }}</td>
              <td>{{ row.from }}</td>
              <td align="center">
                <div class="quickFlex around">
                  <div>£</div>
                  <div>{{ format.money(row.amount, true) }}</div>
                </div>
              </td>
              <td align="center">
                <div class="type" v-tooltip="$format.humanizeText(row.type)" v-if="gettype(row.type)">
                  <i :class="gettype(row.type)"></i>
                </div>
                <div v-else>{{ row.type }}</div>
              </td>
              <td>
                <div class="quickFlex">
                  <div :class="'clickable mar-r5 ' + (row.attachments && row.attachments.length ? ' textWhite' : 'textDarkGrey')" @click="() => (attachments = row)">
                    <i class="fas fa-paperclip"></i>
                  </div>
                  <div class="clickable" @click="() => (entry = row)">
                    <i class="far fa-edit"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="count > limit" class="col-xs-12 mar-t20 mar-l10">
        <Pagination :count="count" :limit="limit" :onPage="changePage" :current="page" :maxPages="10" />
      </div>
    </div>

    <Drawer :isOpen="entry" :onClose="() => {}" :width="'500px'">
      <Add v-if="entry" :data="entry" :onFinish="closeDrawer" />
    </Drawer>

    <Drawer :isOpen="importing" :onClose="() => {}" :width="'500px'">
      <Import :accounts="accounts" :cards="cards" v-if="importing" @onFinish="closeDrawer()" />
    </Drawer>

    <Drawer :isOpen="attachments" :onClose="() => {}" :width="'500px'">
      <Attachments :transaction="attachments" v-if="attachments" @onFinish="closeDrawer()" />
    </Drawer>
  </div>
</template>

<script>
import moment from "moment";
import axios from "../../utils/axios";
import format from "../../utils/format";
import Pagination from "../../components/Pagination";
import Add from "./Add";
import download from "../../utils/download";
import Import from "./Import";
import Attachments from "./Attachments";
import Datepicker from "vuejs-datepicker";
export default {
  components: { Pagination, Add, Import, Attachments, Datepicker },
  data() {
    return {
      format,
      loading: true,
      importing: false,
      entry: undefined,
      attachments: undefined,
      showFilters: false,
      timer: undefined,
      fromDate: moment().subtract(1, "month").toDate(),
      toDate: moment().toDate(),
      accounts: [],
      cards: [],
      filters: { from: "", type: "", direction: "" },
      transactions: [],
      profit: 0,
      income: 0,
      expenses: 0,
      limit: 18,
      datefilter: "thismonth",
      count: 0,
      page: 1,
    };
  },
  created() {
    this.getTransactions();
    axios.get("card?skipbalance=true").then((res) => (this.cards = res.data));
    axios.get("bankaccount?skipbalance=true").then((res) => (this.accounts = res.data));
  },
  computed: {},
  methods: {
    gettype(type) {
      switch (type) {
        case "equipment":
          return "far fa-computer-speaker textGreen";
        case "salary":
          return "fal fa-money-check-edit-alt textPrimary";
        case "hosting":
          return "far fa-network-wired textSecondary";
        case "service":
          return "far fa-concierge-bell textSecondary";
        case "sale":
          return "far fa-pound-sign textPrimary";
        case "bank fee":
          return "far fa-piggy-bank textSecondary";
        case "insurance":
          return "fal fa-car-crash textSecondary";
      }
      return "";
    },
    closeDrawer() {
      this.entry = undefined;
      this.importing = false;
      this.attachments = undefined;
      this.getTransactions();
    },
    changePage(page) {
      this.page = page;
      this.getTransactions();
    },
    filter() {
      this.page = 1;
      clearTimeout(this.timer);
      this.timer = setTimeout(this.getTransactions, 200);
    },
    getTransactions() {
      this.loading = true;
      this.count = 0;
      var options = this.buildQuery();

      axios.get("transaction?" + options.join("&")).then((response) => {
        this.transactions = response.data.transactions;
        this.profit = response.data.income + response.data.expenses;
        this.income = response.data.income;
        this.expenses = response.data.expenses;
        this.count = response.data.count;
        this.loading = false;
      });
    },
    exportrows() {
      var options = this.buildQuery();
      download("transaction/export?" + options.join("&"), "export.csv");
    },
    setDateFilter(filter) {
      this.datefilter = filter;
      this.getTransactions();
    },
    buildQuery() {
      var options = ["hideSalary=true", "limit=" + this.limit, "offset=" + this.limit * (this.page - 1)];
      options.push("fromDate=" + moment(this.fromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"));
      options.push("toDate=" + moment(this.toDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"));
      var filters = this.filters;
      if (filters.type) options.push("type=" + filters.type);
      if (filters.from) options.push("from=" + encodeURIComponent(filters.from));
      if (filters.direction) options.push("direction=" + filters.direction);
      if (filters.type === "salary") options.splice(0, 1);
      return options;
    },
  },
};
</script>

<style>
</style>