<template>
  <div class="sidenav hideMob">
    <div v-if="$store.state.user">
      <div>
        <div class="home" v-if="requires('admin')" @click="$router.push('/')">
          <HomeIcon />
          <!-- <img src="../assets/test-logo.svg" height="45" /> -->
          <!-- <i class="far fa-home"></i> -->
        </div>
        <!-- <div v-if="requires('admin')" class="navitem" :class="{active: active('/clients')}" @click="$router.push('/clients')">Clients</div> -->
        <div class="navitem" :class="{active: active('/projects')}" @click="$router.push('/projects')">
          <div class="icon">
            <i class="far fa-tasks"></i>
          </div>
          <div class="title">Projects</div>
        </div>
        <div v-if="requires('admin')" class="navitem" :class="{active: active('/calendar')}" @click="$router.push('/calendar')">
          <div class="icon">
            <i class="far fa-calendar-alt"></i>
          </div>
          <div class="title">Calendar</div>
        </div>
        <div v-if="requires('admin')" class="navitem" :class="{active: active('/bankaccounts')}" @click="$router.push('/bankaccounts')">
          <div class="icon">
            <i class="far fa-piggy-bank"></i>
          </div>
          <div class="title">Accounts</div>
        </div>
        <div v-if="requires('admin')" class="navitem" :class="{active: active('/transactions')}" @click="$router.push('/transactions')">
          <div class="icon">
            <i class="far fa-sack-dollar"></i>
          </div>
          <div class="title">Transactions</div>
        </div>
        <div v-if="requires('admin')" class="navitem" :class="{active: active('/invoices')}" @click="$router.push('/invoices')">
          <div class="icon">
            <i class="far fa-file-invoice"></i>
          </div>
          <div class="title">Invoices</div>
        </div>
        <!-- <div v-if="requires('admin')" class="navitem" :class="{active: active('/templates')}" @click="$router.push('/templates')">Templates</div> -->
      </div>
      <div class="bottom">
        <div class="settings" @click="() => menuOpen = !menuOpen">
          <div v-if="requires('admin')" @click="$router.push('/account')" v-tooltip="'Company'">
            <i class="far fa-building"></i>
          </div>
          <div @click="$router.push('/me')" v-tooltip="'Account'">
            <i class="fas fa-user-astronaut"></i>
          </div>
          <div @click="logout()" v-tooltip="'Log Out'">
            <i class="fas fa-sign-out-alt"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from "./icons/v-logo";
export default {
  components: { HomeIcon },
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    requires(role) {
      return this.$store.state.user.role === "admin";
    },
    active(path) {
      return this.$route.path.indexOf(path) === 0;
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    }
  }
};
</script>

<style>
</style>