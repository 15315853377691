import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Drawer from './components/Drawer';
import Dropdown from './components/Dropdown';
import format from './utils/format';
import Toasted from 'vue-toasted';
import VTooltip from 'v-tooltip'

import "./scss/index.scss";

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

Vue.config.productionTip = false

const plugins = {
  install() {
    Vue.format = format;
    Vue.prototype.$format = format;
  }
}



Vue.use(Toasted, { iconPack: 'fontawesome', className: 'toast', containerClass: 'toast-container', duration: 2000 })
Vue.use(VTooltip)
Vue.use(plugins)
Vue.component('Drawer', Drawer)
Vue.component('Dropdown', Dropdown)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
