<template>
  <div v-if="project" class="row">
    <div class="col-xs-12">
      <h1 class="textWhite">{{project.name}}</h1>
    </div>
    <div class="col-xs-12 verticalMiddle">
      <div class="textWhite">Documents ({{documents.length}})</div>
      <button class="mar-l10 xsmall primary" @click="createDoc()">Add</button>
      <button class="mar-l10 xsmall grey" @click="() => hideDocs = !hideDocs">{{hideDocs ? 'Show' : 'Hide'}}</button>
    </div>
    <div class="col-xs-12 documents" :class="{hide: hideDocs}">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2" v-for="doc in documents" :key="doc.id">
          <div class="document">
            <div class="controls">
              <i class="fal fa-pencil-alt edit" @click="editDocument(doc)"></i>
            </div>
            <div class="title">{{doc.name}}</div>
            <div class="logo" v-if="!doc.image">
              <i :class="getLogo(doc)"></i>
            </div>
            <div class="image" v-if="doc.image">{{doc.image}}</div>
            <div class="link mar-b20">
              <a :href="doc.link" class="button secondary xsmall" target="_blank">view</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 verticalMiddle mar-t30">
      <div class="textWhite">Tasks ({{project.tasks}})</div>
      <button class="mar-l10 xsmall primary" @click="createTask()">Add</button>
      <button class="mar-l10 xsmall blue" @click="toggleComplete()">{{hidecomplete ? 'Show Complete' : 'Hide Complete'}}</button>
    </div>
    <div class="col-xs-12">
      <tasks-table :tasks="tasks" @editTask="editTask" />
    </div>

    <Drawer :isOpen="document" :onClose="() => {}" :width="'500px'">
      <Document v-if="document" :data="document" :project="project" :onFinish="closeDrawer" />
    </Drawer>

    <Drawer :isOpen="task" :onClose="() => {}" :width="'500px'">
      <Task v-if="task" :data="task" :project="project" @onFinish="closeTask()" />
    </Drawer>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import format from "../../utils/format";
import Document from "./Document";
import Task from "./Task";
import TasksTable from "../../components/TasksTable";
export default {
  components: { Document, Task, TasksTable },
  data() {
    return {
      hideDocs: false,
      project: undefined,
      documents: [],
      tasks: [],
      hidecomplete: true,
      document: undefined,
      task: undefined,
      taskEdit: undefined
    };
  },
  mounted() {
    this.getProject();
    this.getDocuments();
    this.getTasks();
  },
  methods: {
    expand(index) {
      let task = this.tasks[index];
      task.expanded = !task.expanded;
      this.$set(this.tasks, index, task);
    },
    toggleComplete() {
      this.hidecomplete = !this.hidecomplete;
      this.getTasks();
    },
    formatdate(date) {
      return format.datetime(date);
    },
    formtime(hours) {
      return format.duration(hours);
    },
    formatstatus(status) {
      switch (status) {
        case "pending":
          return "Pending";
        case "in-progress":
          return "In-Progress";
        case "testing":
          return "Testing";
        case "deployed":
          return "Deployed";
        case "complete":
          return "Complete";
      }
    },
    formattype(type) {
      function camelize(str) {
        return str
          .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index == 0 ? word.toUpperCase() : word.toUpperCase();
          })
          .replace(/\s+/g, "");
      }
      switch (type) {
        case "research":
          return "R&D";
        default:
          return camelize(type);
      }
    },
    statusclass(status) {
      switch (status) {
        case "pending":
          return "grey";
        case "in-progress":
        case "testing":
          return "secondary";
        case "deployed":
        case "complete":
          return "primary";
      }

      return "grey";
    },
    priorityclass(priority) {
      switch (priority) {
        case "low":
          return "grey";
        case "medium":
          return "primary";
        case "high":
          return "purple";
        case "urgent":
          return "secondary";
      }
      return "grey";
    },
    typeclass(priority) {
      switch (priority) {
        case "development":
          return "primary";
        case "bug":
          return "secondary";
        case "research":
        case "design":
          return "purple";
        case "testing":
        case "deployment":
          return "blue";
      }
      return "grey";
    },
    createTask() {
      this.task = {};
    },
    editTask(task) {
      this.task = task;
    },
    closeDrawer() {
      this.document = undefined;
      this.getDocuments();
    },
    closeTask() {
      this.task = undefined;
      this.getTasks();
    },
    closeTaskEdit() {
      this.taskEdit = undefined;
      this.getTasks();
    },
    editDocument(doc) {
      this.document = doc;
    },
    createDoc() {
      this.document = {};
    },
    getProject() {
      axios.get("project/" + this.$route.params.id).then(response => (this.project = response.data));
    },
    getDocuments() {
      axios.get("project/" + this.$route.params.id + "/document").then(response => (this.documents = response.data));
    },
    getTasks() {
      var queryStr = "?";
      if (this.hidecomplete) queryStr += "hidestatus=complete";
      axios.get("project/" + this.$route.params.id + "/task" + queryStr).then(response => (this.tasks = response.data));
    },
    getLogo(doc) {
      switch (doc.type) {
        case "doc":
          return "far fa-file-word";
        case "img":
          return "far fa-file-image";
        case "vid":
          return "far fa-file-video";
        case "design":
          return "fal fa-pencil-ruler";
        case "pdf":
          return "far fa-file-pdf";
        case "mp3":
          return "fal fa-file-music";
        case "zip":
          return "far fa-file-archive";
        case "api":
          return "far fa-brackets-curly";
        default:
          return "far fa-file";
      }
    }
  }
};
</script>

<style>
</style>