<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ client.id ? "Update" : "Create" }} Client</h1>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Name</label>
        <input type="text" v-model="client.name" class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Contact Name</label>
        <input type="text" v-model="client.contactName" class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Email Address</label>
        <input type="email" v-model="client.emailAddress" class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Address</label>
        <textarea rows="5" v-model="client.address" class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Daily Rate</label>
        <input class="white" type="number" v-model="client.dailyRate" />
      </div>
      <div class="col-xs-12 form-group">
        <label for=""
          ><div class="quickFlex">
            <div class="mar-r10">Colour</div>
            <div style="width: 18px; height: 18px; border-radius: 18px; top: -5px; position: relative" :style="{ 'background-color': client.color }"></div></div
        ></label>
        <input class="white" type="text" v-model="client.color" />
      </div>
      <div :class="{ 'col-xs-6': !client.id, 'col-xs-4': client.id }">
        <button class="primary full-width mobilesmall" @click="save()">Save</button>
      </div>
      <div :class="{ 'col-xs-6': !client.id, 'col-xs-4': client.id }">
        <button class="full-width mobilesmall" @click="onFinish()">Cancel</button>
      </div>
      <div v-if="client.id" class="col-xs-4">
        <button class="secondary full-width mobilesmall" @click="deleteclient()">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../../utils/axios";
export default {
  props: ["data", "onFinish"],
  data() {
    return {
      client: {},
    };
  },
  mounted() {
    if (this.data) {
      this.client = JSON.parse(JSON.stringify(this.data));
      if (this.client.dailyRate === undefined) this.client.dailyRate = 250;
    }
  },
  methods: {
    deleteclient() {
      axios.delete("client/" + this.client.id).then(this.onFinish);
    },
    save() {
      var promise;
      if (this.client.id) {
        promise = axios.put("client/" + this.client.id, this.client);
      } else {
        promise = axios.post("client", this.client);
      }
      promise.then(this.onFinish);
    },
  },
};
</script>

<style>
</style>