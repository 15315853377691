<template>
  <div>
    <div class="row">
      <div class="col-xs-12"></div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
export default {
  data() {
    return {
      account: undefined
    };
  },
  created() {
    this.getAccount();
  },
  methods: {
    getAccount() {
      axios.get("account").then(res => (this.account = res.data));
    }
  }
};
</script>

<style>
</style>