<template>
  <div class="quickFlex end-xs pagination">
    <div class="pageEnd" @click="onPage(1)">
      <i class="far fa-angle-double-left"></i>
    </div>
    <div class="pageEnd" @click="prev()">
      <i class="far fa-angle-left"></i>
    </div>
    <div class="page" :class="{current: current === page}" v-for="page in pages" @click="() => onPage(page)" :key="page">{{page}}</div>

    <div class="pageEnd" @click="next()">
      <i class="far fa-angle-right"></i>
    </div>
    <div class="pageEnd" @click="onPage(totalPages)">
      <i class="far fa-angle-double-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ["count", "onPage", "limit", "current", "maxPages"],
  methods: {
    next() {
      if (this.totalPages < this.current + 1) return;
      this.onPage(this.current + 1);
    },
    prev() {
      if (this.current > 1) this.onPage(this.current - 1);
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.limit);
    },
    pages() {
      var arr = [];
      var totalPages = Math.ceil(this.count / this.limit);
      for (let i = 0; i < totalPages; i++) {
        arr.push(i + 1);
      }
      var maxPages = this.maxPages;
      if (window.innerWidth < 500) {
        maxPages = 5;
      }
      if (totalPages > maxPages) {
        if (this.current < maxPages / 2) {
          // show the first 10
          return arr.splice(0, maxPages);
        } else if (this.current > totalPages - this.current) {
          return arr.splice(totalPages - maxPages, maxPages);
        } else {
          // show the current page - 4 and the current page + 5
          return arr.splice(this.current - Math.floor(maxPages / 2), maxPages);
        }
      }
      return arr;
    }
  }
};
</script>

<style>
</style>