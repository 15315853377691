<template>
  <div>
    <div class="row">
      <div class="col-xs-12 end-xs mar-t20">
        <button class="primary small" @click="addTemplate()">New Template</button>
      </div>
      <div class="col-xs-12" v-if="!loading">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4" v-for="row in templates" :key="row.id">
            <div class="panel">
              <div class="centerCenter mar-b20">{{row.name}}</div>
              <div class="template" :style="{'background-image': 'url(' + getImg(row) +')'}"></div>
              <div class="centerCenter mar-t20">
                <button class="xsmall grey" @click="editTemplate(row)">edit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Drawer :isOpen="templateId" :onClose="() => {}" :width="'100vw'">
      <Template v-if="templateId" :id="templateId" @onFinish="closeDrawer" />
    </Drawer>
  </div>
</template>

<script>
import moment from "moment";
import axios from "../../../utils/axios";
import format from "../../../utils/format";
import constants from "../../../utils/constants";
import Template from "./Template";
export default {
  components: { Template },
  data() {
    return {
      loading: false,
      templates: [],
      total: 0,
      templateId: undefined
    };
  },
  mounted() {
    this.getTemplates();
  },
  methods: {
    getImg(tmp) {
      return constants.API + "template/" + tmp.id + "/screenshot?token=" + localStorage.getItem("token") + "&now=" + Date.now();
    },
    addTemplate() {
      this.template = {
        html: "",
        json: {}
      };
    },
    editTemplate(item) {
      this.templateId = item.id;
    },
    closeDrawer() {
      this.templateId = undefined;
      this.getTemplates();
    },
    getTemplates() {
      this.loading = true;
      axios.get("template").then(response => {
        this.loading = false;
        this.templates = response.data;
      });
    }
  }
};
</script>

<style>
</style>