import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../utils/auth';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: undefined
  },
  mutations: {
    setUser(state, val) {
      state.user = val;
      auth.user.user = val;
      window.localStorage.setItem('user', JSON.stringify(val));
    },
  },
  actions: {
  },
  modules: {
  }
})
