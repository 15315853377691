<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-md-6 chip-container">
        <div class="chip large" :class="view === 'table' ? 'solid' : ''" @click="() => (view = 'table')">All Invoices</div>
        <div class="chip large" :class="view === 'client' ? 'solid' : ''" @click="() => (view = 'client')">By Client</div>
        <div class="chip large" :class="view === 'year' ? 'solid' : ''" @click="() => (view = 'year')">By Year</div>
        <div class="chip large" :class="view === 'fiscal' ? 'solid' : ''" @click="() => (view = 'fiscal')">By Fiscal</div>
      </div>
      <div class="col-xs-12 col-md-6 end-xs">
        <button class="primary small mobilefull mar-r5" @click="() => (editingInvoice = {})">New Invoice</button>
        <button class="green small mobilefull mar-l5" @click="$router.push('/invoices/schedules')">Schedules</button>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="row" v-if="view === 'table'">
        <div class="col-xs-12">
          <table class="mobiletable invoices">
            <thead>
              <th>Client</th>
              <th>Amount</th>
              <th style="width: 100px; text-align: center">Status</th>
              <th>Send Date</th>
              <th>Due Date</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="invoice in invoices" :key="invoice.id">
                <td>{{ invoice.Client ? invoice.Client.name : "" }}</td>
                <td>{{ format.money(invoice.total) }}</td>
                <td class>
                  <div class="chip" :class="statusColor(invoice)">{{ format.humanizeText(invoice.status) }}</div>
                </td>
                <td>{{ invoice.sendDate ? format.date(invoice.sendDate) : "" }}</td>
                <td>{{ format.date(invoice.dueDate) }}</td>
                <td>
                  <div class="quickFlex">
                    <a class="button xsmall secondary mar-r5" :href="constants.API + 'invoice/' + invoice.id + '/preview?token=' + token" target="_blank">view</a>
                    <button v-if="invoice.status === 'sent'" class="xsmall purple mar-r5" @click="() => (payingInvoice = invoice)">pay</button>
                    <button v-if="invoice.status === 'draft'" class="xsmall mar-r5" @click="() => (editingInvoice = invoice)">edit</button>
                    <button v-if="invoice.status === 'draft'" class="xsmall primary mar-r5" @click="send(invoice)">send</button>
                    <button v-if="invoice.timesheet" class="xsmall green mar-r5" @click="timesheet(invoice)">timesheet</button>
                    <button v-if="invoice.status !== 'draft'" class="xsmall primary mar-r5" @click="download(invoice)">download</button>
                    <button v-if="constants.isMobile.any()" class="xsmall blue" @click="share(invoice)">Share</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-xs-12 mar-t20 mar-b40">
          <Pagination v-if="count > limit" :count="count" :limit="limit" :onPage="changePage" :current="page" :maxPages="10" />
        </div>
      </div>

      <div v-if="view === 'client'" class="row mar-t30">
        <div class="col-xs-12 col-md-3 mar-b30" v-for="row in groupedByClient" :key="row.client.id">
          <div class="panel">
            <div class="textLg textHeavy mar-b20">
              <div class="mar-b5 textXl" :style="{ color: row.client.color }">{{ row.client.name }}</div>
              <div class="textLight">{{ format.money(row.total) }} from {{ row.count }} invoices</div>
            </div>
            <div class="mar-b10" v-for="year of row.years">{{ year.year }}: {{ format.money(year.total) }} from {{ year.count }}</div>
          </div>
        </div>
      </div>

      <div v-if="view === 'year'" class="row mar-t30">
        <div class="col-xs-12 col-md-3 mar-b30" v-for="row in groupByYear" :key="row.year">
          <div class="panel">
            <div class="textLg textHeavy mar-b20">
              <div class="mar-b5 textXl">{{ row.year }}</div>
              <div class="textLight">{{ format.money(row.total) }} from {{ row.count }} invoices</div>
            </div>
            <div class="mar-b10" v-for="client of row.clients">
              <span :style="{ color: client.client.color }" class="textHeavy">{{ client.client ? client.client.name : "" }}</span
              >: {{ format.money(client.result.total) }} from {{ client.result.count }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="view === 'fiscal'" class="row mar-t30">
        <div class="col-xs-12 col-md-3 mar-b30" v-for="year in fiscal" :key="year.financial_year">
          <div class="panel">
            <div class="textLg textHeavy mar-b20">
              <div class="mar-b5 textXl">{{ year.financial_year }}</div>
              <div class="textLight">{{ format.money(year.total) }} from {{ year.count }} invoices</div>
            </div>
            <div class="mar-b10" v-for="row of year.rows">
              <span :style="{ color: row.client.color }" class="textHeavy">{{ row.client ? row.client.name : "" }}</span
              >: {{ format.money(row.total) }} from {{ row.count }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Drawer :isOpen="editingInvoice" :onClose="() => {}" :width="'500px'">
      <EditInvoice v-if="editingInvoice" :data="editingInvoice" @onFinish="closeInvoice()" />
    </Drawer>
    <Drawer :isOpen="payingInvoice" :onClose="() => {}" :width="'500px'">
      <PayInvoice v-if="payingInvoice" :invoice="payingInvoice" @onFinish="closeInvoice()" />
    </Drawer>
  </div>
</template>
<script>
import moment from "moment";
import axios from "../../utils/axios";
import format from "../../utils/format";
import constants from "../../utils/constants";
import download from "../../utils/download";
import share from "../../utils/share";
import EditInvoice from "./EditInvoice";
import PayInvoice from "./PayInvoice";
import Pagination from "../../components/Pagination";
export default {
  components: { EditInvoice, PayInvoice, Pagination },
  data() {
    return {
      invoices: [],
      clients: [],
      grouped: [],
      byyear: [],
      fiscal: [],
      format,
      view: "table",
      constants,
      payingInvoice: undefined,
      editingInvoice: undefined,
      limit: 10,
      count: 0,
      page: 1,
    };
  },
  created() {
    this.getInvoices();
    this.getClients();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.getInvoices();
    },
    getInvoices() {
      var queryStr = "?limit=" + this.limit + "&offset=" + this.limit * (this.page - 1);
      axios.get("invoice" + queryStr).then((res) => {
        this.count = res.data.count;
        this.invoices = res.data.invoices.map((r) => {
          if (r.remainingAmount && r.dueDate && moment(r.dueDate) < moment()) {
            r.status = "due";
          }
          return r;
        });
      });
    },
    getClients() {
      axios.get("client").then((res) => (this.clients = res.data));
      this.getByClient();
      this.getByFiscal();
    },
    getByClient() {
      axios.get("invoice/byclient").then((res) => {
        this.grouped = res.data;
      });
    },
    getByFiscal() {
      axios.get("invoice/byfiscal").then((res) => {
        this.fiscal = res.data;
      });
    },
    send(invoice) {
      this.$toasted.show("Sending Invoice");
      axios
        .put("invoice/" + invoice.id + "/generate")
        .then(this.getInvoices)
        .catch((err) => {
          this.$toasted.error(err.data);
        });
    },
    share(invoice) {
      if (navigator.share) {
        var name = invoice.Client.name + " - ";
        name += invoice.reference ? invoice.reference + " - " : "";
        name += invoice.id + ".pdf";
        navigator.share({
          title: name,
          file: [constants.API + "invoice/" + invoice.id + "/download.pdf?token=" + localStorage.getItem("token")],
        });
      } else {
        this.$toasted.show("Cannot Share");
      }
    },
    download(invoice) {
      this.$toasted.show("Downloading Invoice");
      var name = invoice.Client.name + " - ";
      name += invoice.reference ? invoice.reference + " - " : "";
      name += invoice.id + ".pdf";
      download("invoice/" + invoice.id + "/download.pdf", name);
    },
    timesheet(invoice) {
      this.$toasted.show("Downloading Timesheet");
      var name = invoice.Client.name + " - ";
      name += invoice.reference ? invoice.reference + " - " : "";
      name += invoice.id + ".csv";
      download("invoice/" + invoice.id + "/timesheet", name);
    },
    closeInvoice() {
      this.editingInvoice = undefined;
      this.payingInvoice = undefined;
      this.getInvoices();
    },
    statusColor(invoice) {
      switch (invoice.status) {
        case "draft":
          return "grey";
        case "sent":
          return "primary";
        case "due":
          return "secondary";
        case "paid":
          return "green";
      }

      return "grey";
    },
    filteredFiscal(year) {
      return this.fiscal.rows.filter((r) => r.financial_year === year);
    },
  },
  computed: {
    token() {
      return localStorage.token;
    },
    groupedByClient() {
      let allYears = Array.from(new Set(this.grouped.map((g) => g.year)));
      let results = [];
      for (let client of Array.from(new Set(this.grouped.map((g) => g.ClientId))).map((c) => this.clients.find((cl) => cl.id === c))) {
        let rows = this.grouped.filter((r) => r.ClientId === client.id);
        let total = rows.reduce((a, b) => a + b.total, 0);
        let count = rows.reduce((a, b) => a + b.count, 0);
        results.push({
          client,
          total,
          count,
          years: allYears.map((y) => rows.find((r) => r.year === y) || { year: y, total: 0, count: 0 }),
        });
      }
      return results;
    },
    groupByYear() {
      let results = [];
      let activeClients = Array.from(new Set(this.grouped.map((g) => g.ClientId))).map((c) => this.clients.find((cl) => cl.id === c));
      let allYears = Array.from(new Set(this.grouped.map((g) => g.year)));
      for (let year of allYears) {
        let rows = this.grouped.filter((g) => g.year === year);
        let result = {
          year: year,
          total: rows.reduce((a, b) => a + b.total, 0),
          count: rows.reduce((a, b) => a + b.count, 0),
          clients: [],
        };
        results.push(result);
        for (let client of activeClients) {
          let subrow = rows.find((r) => r.ClientId === client.id);
          result.clients.push({ client: client, result: subrow || { total: 0, count: 0 } });
        }
      }
      return results;
    },
  },
};
</script>