<template>
  <div>
    <div class="row">
      <div class="col-xs-12 centerCenter textWhite" style="position: relative">
        <span class="mar-r10 clickable" @click="move(true)">
          <i class="fas fa-arrow-left"></i>
        </span>
        <h1 class="textWhite" style="width: 200px">{{ year }} - {{ monthname }}</h1>
        <span class="mar-l10 clickable" @click="move()">
          <i class="fas fa-arrow-right"></i>
        </span>
        <div class="hideMob" style="position: absolute; right: 30px">
          <button class="xsmall primary" @click="showTimesheet = true">Timesheet</button>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="mar-b20 quickFlex center mobNoFlex">
          <div class="chip round primary mar-r10 mar-b10 min-width">Income: {{ $format.money(income || 0) }}</div>
          <div class="chip round grey mar-r10 mar-b10 min-width">Expenses: {{ $format.money(expenses || 0) }}</div>
          <div class="chip round secondary mar-r10 mar-b10 min-width">Tax: {{ $format.money(tax || 0) }}</div>
          <div class="chip round green mar-r10 mar-b10 min-width">Profit: {{ $format.money(profit || 0) }}</div>
        </div>
      </div>
      <div class="col-xs-12" v-if="clientIncoming.length">
        <div class="mar-b20 quickFlex center">
          <div v-for="client in clientIncoming" :key="client.Client.id" class="chip round white mar-r10 textHeavy" :style="{ color: client.Client.color }">{{ client.Client.shortName || client.Client.name }}: {{ $format.money(client.total) }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-xl-8 col-xl-offset-2 calendar textCenter textWhite mar-b5 hideMob">
        <div class="week">
          <div style="flex-grow: 1">Sun</div>
          <div style="flex-grow: 1">Mon</div>
          <div style="flex-grow: 1">Tue</div>
          <div style="flex-grow: 1">Wed</div>
          <div style="flex-grow: 1">Thur</div>
          <div style="flex-grow: 1">Fri</div>
          <div style="flex-grow: 1">Sat</div>
        </div>
      </div>
      <div class="col-xs-12 col-xl-8 col-xl-offset-2 calendar">
        <div class="week" v-for="(week, weekIndex) in weeks">
          <div
            class="day"
            :class="{
              hide: !week[day],
              noBottom: weekIndex === 0,
              borLeft: day === 0 || (week[day] && week[day].dateStr == 1),
              borTop: week[day] && (week[day].dateStr < 8 || weekIndex === 1),
              today: isToday(week[day] ? week[day].date : undefined),
              weekend: isWeekend(week[day] ? week[day].date : undefined),
            }"
            v-for="day in daysOfWeek"
          >
            <div v-if="week[day]" class="dayContainer">
              <div class="date">{{ week[day].dateStr }}</div>
              <transition-group name="list" tag="div" class="entries">
                <div class="entry" :style="{ 'background-color': $format.hexToRgb(entry.Client ? entry.Client.color || '#757575' : '#757575', entry.days) }" v-for="entry in week[day].entries" :key="entry.id" @click="openEntryDrawer(entry)">{{ entry.Client ? entry.Client.shortName || entry.Client.name : entry.title }}</div>
              </transition-group>
              <div class="addEntry" @click="addEntry(week[day].date)">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Drawer :isOpen="showingEntry" :onClose="() => {}" :width="'500px'">
      <Entry v-if="showingEntry" :data="showingEntry" :onFinish="closedEntryDrawer" :clients="clients" :projects="projects" @deleted="deleted" />
    </Drawer>

    <Drawer :isOpen="showTimesheet" :onClose="() => {}" :width="'400px'">
      <Timesheet v-if="showTimesheet" :close="() => (showTimesheet = false)" :clients="clients" :projects="projects" />
    </Drawer>
  </div>
</template>

<script>
import moment from "moment";
import axios from "../../utils/axios";
import Entry from "./Entry";
import Timesheet from "./Timesheet";
export default {
  components: { Entry, Timesheet },
  mounted() {
    this.showDays();
    this.getClients();
    this.getProjects();

    document.onkeyup = (event) => {
      if (!this.showingEntry && event.srcElement.tagName !== "INPUT") {
        if (event.code === "ArrowRight") {
          this.move();
          event.stopPropagation();
          event.preventDefault();
          return false;
        }
        if (event.code === "ArrowLeft") {
          this.move(true);
          event.stopPropagation();
          event.preventDefault();
          return false;
        }
      }
    };
  },
  beforeDestroy() {
    document.onkeyup = undefined;
  },
  data() {
    return {
      year: moment().year(),
      month: moment().month(),
      weeks: [],
      entries: [],
      clients: [],
      projects: [],
      clientIncoming: [],
      showingEntry: undefined,
      income: 0,
      taxRate: 30,
      profit: 0,
      expenses: 0,
      tax: 0,
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      showTimesheet: false,
    };
  },
  methods: {
    isToday(date) {
      return moment().isSame(date, "day");
    },
    deleted(id) {
      var index = this.entries.findIndex((e) => e.id === id);
      if (index > -1) {
        this.entries.splice(index, 1);
      }
      this.weeks.forEach((week, i) => {
        for (var prop in week) {
          if (week[prop] && week[prop].entries) {
            var index = week[prop].entries.findIndex((e) => e.id === id);
            if (index > -1) {
              week[prop].entries.splice(index, 1);
              this.$set(this.weeks, i, week);
            }
          }
        }
      });
      this.closedEntryDrawer();
    },
    move(back) {
      var year = this.year + "";
      var month = this.month + 1 + "";
      month.padStart(2, "0");
      var date = moment(year + "/" + month, "YYYY/MM");
      date = date.add(back ? -1 : 1, "month");
      this.year = date.year();
      this.month = date.month();
      this.showDays();
    },
    addEntry(date) {
      this.showingEntry = { date, days: 1 };
    },
    openEntryDrawer(entry) {
      this.showingEntry = entry;
    },
    closedEntryDrawer() {
      this.showingEntry = undefined;
      this.getEntries();
    },
    getClients() {
      axios.get("client").then((response) => {
        this.clients = response.data;
      });
    },
    getProjects() {
      axios.get("project").then((response) => {
        this.projects = response.data;
      });
    },
    isWeekend(date) {
      return date && (date.day() === 0 || date.day() === 6);
    },
    showDays() {
      this.buildWeeks();
      this.getEntries();
    },
    buildWeeks() {
      var year = this.year + "";
      var month = this.month + 1 + "";
      month.padStart(2, "0");
      let weeks = [];
      let week = {};
      weeks.push(week);
      let day = moment.utc(year + "/" + month, "YYYY/MM").startOf("month");
      let weekDay = day.weekday();
      week[weekDay] = {
        entries: [],
        date: moment.utc(day),
        day: moment.utc().format("ddd"),
        dateStr: moment.utc(day).format("D"),
      };
      day.add(1, "day");
      while (day.date() > 1) {
        let weekDay = day.weekday();
        if (weekDay === 0) {
          let newWeek = {};
          weeks.push(newWeek);
          week = newWeek;
        }
        week[weekDay] = {
          entries: [],
          date: moment.utc(day),
          day: day.format("ddd"),
          dateStr: moment.utc(day).format("D"),
        };
        day.add(1, "day");
      }
      this.weeks = weeks;
    },
    getEntries() {
      this.income = 0;
      this.profit = 0;
      var year = this.year + "";
      var month = this.month + 1 + "";
      month.padStart(2, "0");
      var date = moment.utc(year + "/" + month, "YYYY/MM");
      var queryStr = "?startDate=" + date.startOf("month") + "&endDate=" + date.endOf("month");
      axios.get("calendarentry" + queryStr).then((response) => {
        this.entries = response.data;
        this.clientIncoming = [];
        this.days;
        this.entries.forEach((entry) => {
          var client = this.clientIncoming.find((c) => c.Client.id === entry.ClientId);
          if (!client && entry.Client) {
            client = { Client: entry.Client, total: 0 };
            this.clientIncoming.push(client);
          }
          client.total += entry.days * client.Client.dailyRate;
          let date = moment.utc(entry.date);
          let week = this.weeks.find((w) => w[date.weekday()] && w[date.weekday()].dateStr === date.format("D"));
          if (week) {
            if (!week[date.weekday()].entries.some((e) => e.id === entry.id)) week[date.weekday()].entries.push(entry);
          }
        });

        if (this.entries.length) {
          this.income = this.entries.map((e) => e.Client.dailyRate * e.days).reduce((a, b) => b + a);
        }

        this.getExpenses();
      });
    },
    getExpenses() {
      var year = this.year + "";
      var month = this.month + 1 + "";
      month.padStart(2, "0");
      var date = moment.utc(year + "/" + month, "YYYY/MM");
      var queryStr = "?fromDate=" + date.startOf("month").format("YYYY-MM-DD HH:mm:ss") + "&toDate=" + date.endOf("month").format("YYYY-MM-DD HH:mm:ss") + "&hideSalary=true&limit=1";
      axios.get("transaction" + queryStr).then((response) => {
        this.expenses = response.data.expenses;
        this.profit = this.income + this.expenses;
        this.tax = this.profit * (this.taxRate / 100);
        this.profit -= this.tax;
        this.profit += Math.abs(response.data.manual);
      });
    },
  },
  computed: {
    monthname() {
      return moment().month(this.month).format("MMMM");
    },
    today() {
      return moment().format("YYYY-MM-DD");
    },
    header() {
      return this.days.slice(0, 7).map((d) => d.date.format("ddd"));
    },
  },
};
</script>

<style>
</style>