<template>
  <div>
    <div class="row">
      <div class="col-xs-12 center-xs">
        <h1 class="textWhite">Loading Accounts</h1>
        <div class="textSuper textWhite loadingicon">
          <i v-if="!error" class="fad fa-spinner-third fa-spin"></i>
          <div v-if="error" class="textLg mar-b30 mar-t30">{{error}}</div>
          <button v-if="error" class="primary" @click="() => $router.push('/bankaccounts')">Back To Accounts</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../utils/axios";
export default {
  data() {
    return {
      error: ""
    };
  },
  mounted() {
    axios
      .post("oauth?provider=1", {
        token: this.$route.query.code
      })
      .then(() => {
        this.$router.push("/bankaccounts");
      })
      .catch(err => {
        console.log(err);
        this.error = "There was a problem connecting to your Bank, please try again";
      });
  }
};
</script>

<style>
</style>