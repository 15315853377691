<template>
  <div class="dropdown" :class="{active:menuOpen}">
    <div ref="dropbtn" class="dropbtn" @click="() => menuOpen = !menuOpen">
      <slot name="button"></slot>
    </div>
    <div class="dropdown-content" :class="className">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["className"],
  data() {
    return {
      menuOpen: false
    };
  },
  mounted() {
    window.onclick = event => {
      this.clickaway(event);
    };
  },
  methods: {
    clickaway(event) {
      function isDescendant(parent, child) {
        var node = child.parentNode;
        while (node != null) {
          if (node == parent) {
            return true;
          }
          node = node.parentNode;
        }
        return false;
      }
      if (!isDescendant(this.$refs.dropbtn, event.target)) {
        this.menuOpen = false;
      }
    }
  }
};
</script>

<style>
</style>