<template>
  <div>
    <div class="mar-b30 desktopFlex">
      <div class="textWhite mar-r10 mar-b10" v-if="accounts.length">
        Accounts:
        <span class="textGrey">{{ $format.money(totalAccountBalance) }} ({{ $format.money(totalAccountAvailable) }} Available)</span>
      </div>
      <div class="textWhite mar-r10 mar-b10" v-if="cards.length">
        Cards:
        <span class="textGrey">{{ $format.money(totalCardBalance) }} ({{ $format.money(totalCardAvailable) }} Available)</span>
      </div>
      <div class="textWhite mar-b10" v-if="crypto.length">
        Crypto:
        <span class="textGrey">{{ $format.money(totalCryptoBalance) }} - Spent {{ $format.money(Math.abs(cryptoCosts)) }} - {{ $format.money(totalCryptoBalance + cryptoCosts) }} Profit</span>
      </div>
      <div style="margin-left: auto">
        <button class="primary small">
          <a class="textWhite noUnderline" href="https://auth.truelayer.com/?response_type=code&client_id=endevrs-17b8f5&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=https://endevrs.com/callback&providers=uk-ob-all%20uk-oauth-all">Add Account or Card</a>
        </button>
      </div>
    </div>
    <div class="row">
      <div v-for="(account, index) in accounts" class="col-xs-12 col-sm-6 col-md-6 col-lg-3 mar-b30">
        <Account :account="account" :loaded="account.loaded" :balance="account.balance" :setAccount="(a) => updateAccount(index, a)" />
      </div>
      <div v-for="(card, index) in cards" class="col-xs-12 col-sm-6 col-md-6 col-lg-3 mar-b30">
        <Card :card="card" :loaded="card.loaded" :balance="card.balance" :setCard="(c) => updateCard(index, c)" />
      </div>
      <div v-for="(account, index) in crypto" :key="account.currency" class="col-xs-12 col-sm-6 col-md-6 col-lg-3 mar-b30">
        <Crypto :account="account" />
      </div>
      <div v-if="!cryptoLoaded" class="col-xs-12 center-xs">
        <div class="textXxl loadingicon">
          <i class="fad fa-spinner-third fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import Account from "./Account";
import Card from "./Card";
import Crypto from "./Crypto";
export default {
  components: { Account, Card, Crypto },
  data() {
    return {
      accounts: [],
      cards: [],
      accountsLoaded: false,
      cardsLoaded: false,
      cryptoLoaded: false,
      crypto: [],
      cryptoCosts: 0,
      timer: undefined,
    };
  },
  mounted() {
    this.getAccounts();
    this.getCards();
    this.getCrypto();
    this.getCryptoCosts();

    this.timer = setInterval(this.getCrypto, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getAccounts() {
      axios.get("bankaccount?skipbalance=true").then((res) => {
        this.accounts = res.data;
        this.accountsLoaded = true;
        this.accounts.forEach((account, index) => {
          axios.get("bankaccount/" + account.id + "/balance").then((res) => {
            account.balance = res.data;
            account.loaded = true;
            this.$set(this.accounts, index, account);
          });
        });
      });
    },
    getCards() {
      axios.get("card?skipbalance=true").then((res) => {
        this.cards = res.data;
        this.cardsLoaded = true;
        this.cards.forEach((card, index) => {
          axios.get("card/" + card.id + "/balance").then((res) => {
            card.balance = res.data;
            card.loaded = true;
            this.$set(this.cards, index, card);
          });
        });
      });
    },
    getCrypto() {
      axios.get("crypto/accounts").then((res) => {
        this.crypto = res.data;
        this.cryptoLoaded = true;
      });
    },
    getCryptoCosts() {
      axios.get("transaction/hidden?type=crypto").then((res) => {
        var total = 0;
        res.data.forEach((row) => (total += row.amount));
        this.cryptoCosts = total;
      });
    },
    updateAccount(index, account) {
      this.$set(this.accounts, index, account);
    },
    updateCard(index, card) {
      this.$set(this.cards, index, card);
    },
  },
  computed: {
    totalAccountBalance() {
      var total = 0;
      this.accounts.forEach((a) => {
        if (a.balance) {
          total += a.balance.current !== undefined ? a.balance.current : a.balance.balance;
        }
      });
      return total;
    },
    totalAccountAvailable() {
      var total = 0;
      this.accounts.forEach((a) => {
        if (a.balance) {
          total += a.balance.available !== undefined ? a.balance.available : a.balance.availableBalance;
        }
      });
      return total;
    },
    totalCardBalance() {
      var total = 0;
      this.cards.forEach((a) => {
        if (a.balance) {
          total += a.balance.current !== undefined ? a.balance.current : a.balance.balance;
        }
      });
      return total;
    },
    totalCardAvailable() {
      var total = 0;
      this.cards.forEach((a) => {
        if (a.balance) {
          total += a.balance.available !== undefined ? a.balance.available : a.balance.availableBalance;
        }
      });
      return total;
    },
    totalCryptoBalance() {
      var total = 0;
      this.crypto.forEach((a) => {
        if (a.value) {
          total += a.value;
        }
      });
      return total;
    },
  },
};
</script>

<style>
</style>