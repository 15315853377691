import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Transactions from '../views/Transactions/Transactions.vue'
import Calendar from '../views/Calendar/Calendar.vue'
import Projects from '../views/Projects/Projects.vue'
import Project from '../views/Projects/Project.vue'
import Task from '../views/Projects/TaskDetail.vue';
import Invoices from '../views/Invoices/Invoices.vue';
import InvoiceSchedules from '../views/Invoices/Schedules.vue'
import Me from '../views/User.vue'
import OAuth from '../views/OAuth.vue'
import BackAccounts from '../views/BankAccounts/Accounts.vue'
import AccountTransactions from '../views/BankAccounts/Transactions.vue'
import AccountTemplate from '../views/Account/Template.vue'
import Account from '../views/Account/Account.vue'
import Templates from '../views/Account/Templates/Templates.vue';
import Clients from '../views/Account/Clients/Clients.vue'
import Users from '../views/Account/Users/Users.vue'
import Activate from '../views/Activate.vue';

import auth from '../utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      roles: ['admin'],
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/activate',
    name: 'activate',
    component: Activate
  },
  {
    path: '/callback',
    name: 'callback',
    component: OAuth,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: Transactions,
    meta: {
      roles: ['admin', 'accountant'],
      requiresAuth: true
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      roles: ['admin'],
      requiresAuth: true
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      roles: ['admin', 'client'],
      requiresAuth: true
    }
  },
  {
    path: '/projects/:id',
    component: Project,
    meta: {
      roles: ['admin', 'client'],
      requiresAuth: true
    }
  },
  {
    path: '/projects/:id/tasks/:taskId',
    component: Task,
    meta: {
      roles: ['admin', 'client'],
      requiresAuth: true
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: Invoices,
    meta: {
      roles: ['admin'],
      requiresAuth: true
    }
  },
  {
    path: '/invoices/schedules',
    name: 'invoiceSchedules',
    component: InvoiceSchedules,
    meta: {
      roles: ['admin'],
      requiresAuth: true
    }
  },
  {
    path: '/me',
    name: 'me',
    component: Me,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bankaccounts',
    name: 'bankAccounts',
    component: BackAccounts,
    meta: {
      roles: ['admin'],
      requiresAuth: true
    }
  },
  {
    path: '/bankaccounts/:type/:id/transactions',
    name: 'bankAccountTransactions',
    component: AccountTransactions,
    meta: {
      roles: ['admin'],
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'account',
    component: AccountTemplate,
    meta: {
      requiresAuth: true
    },
    children: [{
      path: '',
      component: Account
    }, {
      path: 'clients',
      name: 'clients',
      component: Clients,
      meta: {
        roles: ['admin'],
        requiresAuth: true
      }
    },
    {
      path: 'templates',
      name: 'templates',
      component: Templates,
      meta: {
        roles: ['admin'],
        requiresAuth: true
      }
    },
    {
      path: 'users',
      name: 'users',
      component: Users,
      meta: {
        roles: ['admin'],
        requiresAuth: true
      }
    }]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  var user = localStorage.getItem('user')
  if (user) {
    try {
      user = JSON.parse(user)
    } catch (e) {

    }
  } else {
    user = {}
  }
  if (to.matched[to.matched.length - 1].meta.requiresAuth === false) return next()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.matched.some(record => record.meta.roles && record.meta.roles.indexOf(user.role) > -1)) {
      return next();
    }
    if (to.matched.some(record => record.meta.roles)) {
      // default to their first route this user can see
      var found = routes.find(r => r.meta && r.meta.roles && r.meta.roles.indexOf(user.role) > -1)
      if (found) return next(found)
      else next({
        path: "/login",
      });
    }
    if (!auth.isAuthenticated()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }

  if (to.matched.some(record => record.meta.requiresRole)) {
    let user = store.getters.getUser()
    if (user && user.role === to.meta.requiresRole) next()
  }
});

export default router
