<template>
  <div>
    <div class="row">
      <div class="col-xs-12 end-xs mar-b30">
        <button class="primary small" @click="editUser = {}">Invite User</button>
      </div>
      <div class="col-xs-12 col-md-3" v-for="user in users" :key="user.id">
        <div class="panel mar-b30">
          <div class="row">
            <div class="col-xs-12 center-xs mar-b30">
              {{user.name}} -
              <a :href="'mailto:' + user.email">{{user.email}}</a>
            </div>
            <div class="col-xs-12 center-xs mar-b30">{{$format.humanizeText(user.AccountUser.role)}} {{user.AccountUser.Client ? ' - ' + user.AccountUser.Client.name : ''}}</div>
            <div class="col-xs-12 center-xs mar-b30">Last Action - {{$format.datetime(user.lastAccessDate)}}</div>
            <div class="col-xs-12 center-xs">
              <button class="tiny" @click="editUser = user">Edit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Drawer :isOpen="editUser" :onClose="() => {}" :width="'500px'">
      <EditUser v-if="editUser" :data="editUser" @onFinish="closeDrawer()" />
    </Drawer>
  </div>
</template>

<script>
import axios from "../../../utils/axios";
import EditUser from "./Edit";
export default {
  components: { EditUser },
  data() {
    return {
      users: [],
      editUser: undefined
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      axios.get("user").then(res => (this.users = res.data));
    },
    closeDrawer() {
      this.editUser = undefined;
      this.getUsers();
    }
  }
};
</script>

<style>
</style>