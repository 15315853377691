import moment from 'moment'

export default {
    datetime(input) {
        if (!input) return 'Never'
        return moment(input).format('YYYY-MM-DD HH:mm:ss')
    },
    date(input) {
        if (!input) return 'Never'
        return moment(input).format('YYYY-MM-DD')
    },
    duration(hours) {
        if (!hours) hours = 0
        if (hours < 8) {
            return hours + ' hour' + (hours > 1 ? 's' : '')
        }
        var days = Math.floor(hours / 8)
        var remaining = hours % 8
        return days + ' day' + (days > 1 ? 's' : '') + (remaining ? ' ' + remaining + ' hour' + (remaining > 1 ? 's' : '') : '')
        // return moment.duration(hours, 'hours').humanize()
    },
    money(input, hide, decimals) {
        if (!input) input = 0
        var negative = false
        if (input < 0) negative = true;
        input = Math.abs(input)
        var str = input.toLocaleString()
        if (decimals === 0) {
            return '£' + str;
        }
        var num = input.toFixed(2)
        if (negative) return '(' + str.split('.')[0] + '.' + num.split('.')[1] + ')'
        return (!hide ? '£' : '') + str.split('.')[0] + '.' + num.split('.')[1]
    },
    percent(total, used, decimals) {
        if (!decimals) decimals = 0
        var num = used / total
        if (isNaN(num)) num = 1
        return (num * 100).toFixed(decimals) + '%'
    },
    percentInc(old, cur, decimals) {
        var num;
        if (old > cur) num = old - cur
        else num = cur - old
        num = num / old
        if (!decimals) decimals = 0
        if (isNaN(num)) num = 1
        return (num * 100).toFixed(decimals) + '%'
    },
    humanizeText(str) {
        if (!str) str = ''
        return str
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, str => str.toUpperCase())
    },
    hexToRgb(hex, opacity) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        var o = result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
        return `rgba(${o.r}, ${o.g}, ${o.b}, ${opacity})`
    },
    copy(obj) {
        if (!obj) return obj
        return JSON.parse(JSON.stringify(obj))
    }
}