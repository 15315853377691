<template>
  <div class>
    <div class="row">
      <div class="col-xs-12 mar-b30">
        <button class="primary" @click="AddUser()">Add User</button>
      </div>
      <div class="col-xs-12 mar-b30" v-if="newUser">
        <div class="form-group">
          <label for>Email</label>
          <input v-model="newUser.email" type="email" />
        </div>
        <div class="form-group">
          <label for>Role</label>
          <select v-model="newUser.role">
            <option value="admin">Admin</option>
            <option value="user">User</option>
            <option value="client">Client</option>
          </select>
        </div>
        <button class="primary" @click="create()">Save</button>
      </div>
      <div class="col-xs-12">
        <div class="row" v-for="user in users" :key="user.id">
          <div class="col-xs-5 textWhite mar-t10">{{user.email}}</div>
          <div class="col-xs-5 form-group">
            <select v-model="user.Accounts[0].AccountUser.role">
              <option value="admin">Admin</option>
              <option value="user">User</option>
              <option value="client">Client</option>
            </select>
          </div>
          <div class="col-xs-2 quickFlex center">
            <div class="textPrimary clickable textXl mar-r5" @click="save(user)">
              <i class="far fa-save"></i>
            </div>
            <div class="textSecondary clickable textXl mar-l5" @click="remove(user)">
              <i class="far fa-times"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 mar-t30">
        <button class="primary full-width" @click="() => $emit('onFinish')">Save</button>
      </div>

      <div class="col-xs-6 mar-t30">
        <button class="full-width" @click="() => $emit('onFinish')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../../utils/axios";
export default {
  props: ["ClientId"],
  data() {
    return {
      users: [],
      newUser: undefined
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      axios.get("client/" + this.ClientId + "/users").then(res => (this.users = res.data));
    },
    save(user) {},
    remove(user) {},
    addUser() {
      this.newUser = { role: "user" };
    },
    create() {
      if (!this.newUser.email) return;
      // create the user and invite them to the account
    }
  }
};
</script>

<style>
</style>