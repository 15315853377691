<template>
  <div class="panel bankaccount">
    <div class="syncing" :class="{ active: account.sync }" @click="sync(index)" v-tooltip="account.sync ? 'Syncing Transactions' : 'Not Syncing Transactions'">
      <i class="fas fa-sync"></i>
    </div>
    <div class="notify" v-if="account.sync" :class="{ active: account.notify }" @click="notify(index)" v-tooltip="account.notify ? 'Notifications ON' : 'Notifications OFF'">
      <i class="far fa-bell"></i>
    </div>
    <div class="center-xs textLg">{{ account.ProviderInstance.name }}</div>
    <div class="center-xs mar-t20 mar-b20">
      <img :src="account.logo" height="60" />
    </div>
    <div class="center-xs mar-b20 clickable" @click="$router.push('/bankaccounts/bankaccount/' + account.id + '/transactions')">{{ account.name }}</div>
    <div class="row center-xs" style="margin: auto 0">
      <div v-if="balance && balance !== 'refresh'" class="col-xs-12 noPad textMed mar-b20">
        <div>{{ $format.money(balance.current !== undefined ? balance.current : balance.balance) }}</div>
        <div class="textSml textDarkGrey">({{ $format.money(balance.available !== undefined ? balance.available : balance.availableBalance) }} Available)</div>
      </div>
      <div v-if="!balance && loaded" class="col-xs-12 textMed mar-b20"><button class="tiny" @click="resync(account)">Resync</button></div>
      <div v-show="!loaded" class="col-xs-2 textMed mar-b20 loadingicon">
        <i class="fad fa-spinner-third fa-spin"></i>
      </div>
      <div class="col-xs-6 textSml mar-b10 end-xs">{{ account.accountNumber }}</div>
      <div class="col-xs-6 textSml mar-b10 start-xs">{{ account.sortCode }}</div>
      <div class="col-xs-12 textSml">IBAN: {{ account.iban || "N/A" }}</div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
export default {
  props: { account: Object, setAccount: Function, balance: Object, loaded: Boolean },
  methods: {
    sync() {
      axios
        .put("bankaccount/" + account.id, {
          sync: !account.sync,
        })
        .then((res) => {
          account.sync = res.data.sync;
          this.setAccount(account);
        });
    },
    notify() {
      axios
        .put("bankaccount/" + account.id, {
          notify: !account.notify,
        })
        .then((res) => {
          account.notify = res.data.notify;
          setAccount(account);
        });
    },
    resync() {
      window.location = "https://auth.truelayer.com/?response_type=code&client_id=endevrs-17b8f5&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=https://endevrs.com/callback&providers=uk-ob-" + this.account.ProviderInstance.name.toLowerCase() + "%20uk-oauth-all";
    },
  },
};
</script>

<style>
</style>