<template>
  <div class="drawer" :class="[{left: left }, {open:isOpen}, widthCss]" :style="{right: isOpen ? 0 : '-' + width, width}">
    <div class="uiDrawerInner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["onClose", "isOpen", "width", "left"],
  methods: {
    close() {
      this.isOpen = false;
      document.getElementById("content").classList.remove("noscroll");
      document.getElementsByClassName("topnav")[0].style.display = "block";
      if (typeof this.onClose === "function") this.onClose();
    }
  },
  watch: {
    width: function(val) {
      if (["full", "half"].indexOf(val) > -1) {
        this.widthCss = val;
        this.widthStyle = undefined;
      } else {
        this.widthCss = "";
        this.widthStyle = val;
      }
    }
  },
  created() {
    if (["full", "half"].indexOf(this.width) > -1) {
      this.widthCss = this.width;
      this.widthStyle = undefined;
    } else {
      this.widthCss = "";
      this.widthStyle = this.width;
    }
  },
  beforeDestroy() {
    document.getElementById("content").classList.remove("noscroll");
    document.getElementsByClassName("topnav")[0].style.display = "block";
  },
  data() {
    return {
      widthCss: "",
      widthStyle: undefined
    };
  },
  watch: {
    isOpen: function(val) {
      if (val) {
        document.getElementById("content").classList.add("noscroll");
        document.getElementsByClassName("topnav")[0].style.display = "none";
      } else {
        document.getElementById("content").classList.remove("noscroll");
        document.getElementsByClassName("topnav")[0].style.display = "block";
      }
    }
  }
};
</script>

<style>
</style>
