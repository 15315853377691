<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1 class="textWhite">{{account.name}} - Recent Transactions</h1>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-md-offset-2 col-lg-offset-3">
        <div class="form-group">
          <label for>From</label>

          <datepicker v-model="from" :disabled="loading" wrapper-class="calendarwrapper" :disabled-dates="disabledDates" calendar-class="calendarclass" @closed="getTransactions()" input-class="white"></datepicker>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div class="form-group">
          <label for>To</label>
          <datepicker v-model="to" :disabled="loading" wrapper-class="calendarwrapper" :disabled-dates="disabledDates" calendar-class="calendarclass" @closed="getTransactions()" input-class="white"></datepicker>
        </div>
      </div>
      <div class="col-xs-12">
        <table>
          <thead>
            <th>Date Time</th>
            <th>Description</th>
            <th>Amount</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-show="loading">
              <td colspan="99" align="center">
                <div class="textXl">
                  <i class="fad fa-spinner-third fa-spin"></i>
                </div>
              </td>
            </tr>
            <tr v-show="!loading && !transactions.length">
              <td colspan="99" align="center">No Transactions Found</td>
            </tr>
            <tr v-for="row  in transactions">
              <td>{{$format.date(row.timestamp)}}</td>
              <td>{{row.description}}</td>
              <td>{{$format.money(row.amount)}}</td>
              <td>
                <button class="tiny primary" @click="importing = $format.copy(row)">import</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Drawer :isOpen="importing" width="400px">
      <div v-if="importing" class="row">
        <div class="col-xs-12 form-group">
          <label for>Amount</label>
          <input v-model="importing.amount" type="number" disabled />
        </div>
        <div class="col-xs-12 form-group">
          <label for>Description</label>
          <input v-model="importing.description" />
        </div>
        <div class="col-xs-12 form-group">
          <label for>Type</label>
          <select v-model="importing.type">
            <option :value="undefined">--Select Type--</option>
            <option value="sale">Sale</option>
            <option value="salary">Salary</option>
            <option value="hosting">Hosting</option>
            <option value="equipment">Equipment</option>
            <option value="service">Service</option>
            <option value="bank fee">Bank Fee</option>
            <option value="insurance">Insurance</option>
            <option value="crypto">Crypto</option>
          </select>
        </div>
        <div class="col-xs-6">
          <button class="primary small full-width" @click="addToAccount()">Import</button>
        </div>
        <div class="col-xs-6">
          <button class="grey small full-width" @click="importing = undefined">Cancel</button>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },
  data() {
    return {
      loading: true,
      account: {},
      transactions: [],
      from: moment()
        .subtract(1, "week")
        .startOf("day")
        .toDate(),
      to: new Date(),
      disabledDates: {
        from: new Date()
      },
      importing: undefined
    };
  },
  created() {
    this.getAccount();
    this.getTransactions();
  },
  methods: {
    getAccount() {
      axios.get(`${this.$route.params.type}/${this.$route.params.id}`).then(res => (this.account = res.data));
    },
    getTransactions() {
      let from = moment(this.from).startOf("day");
      let to = moment(this.to).endOf("day");
      if (from > moment()) from = moment().startOf("day");
      if (to >= moment()) to = moment().subtract(1, "minute");
      if (from > to) return;
      this.loading = true;
      this.transactions = [];
      var queryStr = `?from=${from.toISOString()}&to=${to.toISOString()}`;
      axios.get(`${this.$route.params.type}/${this.$route.params.id}/transactions${queryStr}`).then(res => {
        this.transactions = res.data;
        this.loading = false;
      });
    },
    addToAccount() {
      let row = this.importing;
      axios
        .post("transaction", {
          amount: this.account.network ? 0 - row.amount : row.amount,
          from: row.description,
          type: row.type,
          transactionId: row.transaction_id,
          datetime: row.timestamp,
          account_id: row.account_id,
          BankProviderId: row.BankProviderId,
          ProviderInstanceId: row.ProviderInstanceId,
          BankAccountId: row.network ? undefined : row.id,
          CardId: row.network ? row.id : undefined
        })
        .then(res => {
          if (res.data.error) {
            this.$toasted.show(res.data.error);
          } else {
            this.importing = undefined;
            this.$toasted.show("Transaction Imported");
          }
        });
    }
  }
};
</script>

<style>
</style>