import axios from "axios";
import constants from "./constants";

var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(config => {
    if (config.method !== "OPTIONS" && !config.headers.Authorization) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    if (config.url.indexOf('http') !== 0) config.url = constants.API + config.url;
    return config;
});

axiosInstance.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    err => {
        if (err.response.status === 401) {
            localStorage.removeItem('token')
            return window.location.href = "/login"
        }
        return Promise.reject(err);
    }
);

export default axiosInstance;
