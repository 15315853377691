<template>
  <div>
    <div class="row">
      <div class="col-xs-12 end-xs mar-t20">
        <button class="primary small mobilefull" @click="addClient()">New Client</button>
      </div>
      <div class="col-xs-12">
        <table class="mobiletable clienttable">
          <thead>
            <th>Name</th>
            <th>Contact</th>
            <th>Rate</th>
            <th style="text-align: center">Projects</th>
            <th style="text-align: center">Users</th>
            <th style="width: 80px"></th>
          </thead>
          <tbody>
            <tr v-for="client in clients" :key="client.id">
              <td>
                <div class="quickFlex">
                  <div class="mar-r10">{{ client.name }}</div>
                  <div style="height: 18px; width: 18px; border-radius: 18px" :style="{ 'background-color': client.color }"></div>
                </div>
              </td>
              <td>
                {{ client.contactName }}
                <a :href="'mailto:' + client.emailAddress">({{ client.emailAddress }})</a>
              </td>
              <td>{{ currency(client.dailyRate) }}</td>
              <td align="center">{{ client.projects }}</td>
              <td align="center">{{ client.users }}</td>
              <td>
                <div class="quickFlex">
                  <button class="xsmall mar-r5" @click="editClient(client)">edit</button>
                  <button class="xsmall purple mar-r5" @click="showusers(client)">users</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Drawer :isOpen="editingClient" :onClose="() => {}" :width="'500px'">
      <Client v-if="editingClient" :data="editingClient" :onFinish="closeDrawer" />
    </Drawer>

    <Drawer :isOpen="users" :onClose="() => {}" :width="'500px'">
      <Users v-if="users" :ClientId="users" @onFinish="closeDrawer()" />
    </Drawer>
  </div>
</template>

<script>
import axios from "../../../utils/axios";
import format from "../../../utils/format";
import Client from "./Client";
import Users from "./Users";
export default {
  components: { Client, Users },
  data() {
    return {
      clients: [],
      total: 0,
      users: undefined,
      editingClient: undefined,
    };
  },
  mounted() {
    this.getClients();
  },
  methods: {
    addClient() {
      this.editingClient = {};
    },
    editClient(client) {
      this.editingClient = client;
    },
    showusers(client) {
      this.users = client.id;
    },
    closeDrawer() {
      this.editingClient = undefined;
      this.users = undefined;
      this.getClients();
    },
    currency(input) {
      return format.money(input);
    },
    getClients() {
      axios.get("client").then((response) => {
        this.clients = response.data;
      });
    },
  },
};
</script>

<style>
</style>