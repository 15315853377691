<template>
  <div>
    <form novalidate @submit.prevent="validateUser" class="loginContainer" autocomplete="off">
      <div>
        <div class style="width: 350px;">
          <div class="row">
            <div class="col-xs-12 mar-b30 center-xs">
              <HomeIcon :height="'80px'" :width="'auto'" :class="'loginicon'" />
              <!-- <img src="../assets/logo.svg" height="80" style="margin-top: -200px" /> -->
              <h1 style="z-index: 99; margin-top: -75px;" class="textWhite textXl">Endevrs</h1>
            </div>
            <div class="col-xs-12">
              <div class="form-group">
                <input type="email" class="white" v-model="form.email" required placeholder="Email Address" />
              </div>
              <div class="form-group">
                <input type="password" class="white" v-model="form.password" required placeholder="Password" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 center-xs">
              <button type="submit" class="primary full-width" :disabled="sending" v-on:click="login">
                <span>Sign In</span>
              </button>
            </div>
            <div v-if="badpassword || bademail" class="col-xs-12 mar-t30 textWhite center-xs">
              <div class="errorHolderWarning">invalid email or password</div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <Wave />
  </div>
</template>

<script>
import axios from "../utils/axios";
import auth from "../utils/auth";
import Wave from "../components/Wave";
import HomeIcon from "../components/icons/v-logo";
import queryString from "query-string";

export default {
  name: "Login",
  components: { Wave, HomeIcon },
  mixins: [],
  data() {
    return {
      form: {
        email: null,
        password: null
      },
      bademail: false,
      sending: false,
      badpassword: false
    };
  },
  created: function() {
    if (auth.isAuthenticated()) {
      this.$router.push("/");
    }
  },
  methods: {
    login() {
      if (!this.form.email) this.bademail = true;
      else this.bademail = false;
      if (!this.form.password) this.badpassword = true;
      else this.badpassword = false;

      this.sending = true;
      axios
        .post("login", this.form)
        .then(response => {
          this.sending = false;
          if (response.data.token) {
            this.badpassword = false;
            this.$store.commit("setUser", response.data.user);
            localStorage.setItem("token", response.data.token);
            auth.user.authenticated = true;
            var query = queryString.parse(location.search);
            if (query.redirect) {
              this.$router.push(query.redirect);
            } else {
              this.$router.push("/");
            }
          } else {
            this.badpassword = true;
            localStorage.removeItem("token");
            auth.user.authenticated = false;
          }
        })
        .catch(() => {
          this.sending = false;
          this.badpassword = true;
          localStorage.removeItem("token");
          auth.user.authenticated = false;
        });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (fieldName == "password" && this.badpassword) return "invalid";
      if (field) {
        return {
          invalid: field.$invalid && field.$dirty
        };
      }
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.login();
      }
    }
  }
};
</script>

<style>
.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
#app {
  height: 100%;
}
.loginicon {
  height: 80px !important;
  width: auto;
}

.loginicon .v-icon {
  fill-opacity: 0.5;
}
</style>
