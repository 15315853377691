<template>
  <div class="home">
    <div class="col-xs-12 hideMob">
      <h1 class="textWhite">Endevrs</h1>
    </div>
    <div class="row">
      <!-- <div class="col-xs-4">
        <Doughnut :chartdata="doughnut" :options="options" />
      </div>-->
      <div class="col-xs-12 col-md-4">
        <div class="panel primary mar-b30">
          <h1 class>Unreserved Days Remaining</h1>
          <div class="centerCenter textXxl mar-b30">{{ freeDays }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="panel green mar-b30">
          <h1 class>Reserved Days Remaining</h1>
          <div class="centerCenter textXxl mar-b30">{{ workingDaysLeft }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="panel secondary mar-b30">
          <h1 class>Days Worked</h1>
          <div class="centerCenter textXxl mar-b30">{{ workedDays }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="panel green mar-b30">
          <h1 class>Pending Invoices #</h1>
          <div class="centerCenter textXxl mar-b30">{{ pending.count }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="panel secondary mar-b30">
          <h1 class>Pending Invoices £</h1>
          <div class="centerCenter textXxl mar-b30">{{ format.money(pending.total) }}</div>
        </div>
      </div>

      <div class="col-xs-12 col-md-3">
        <div class="panel primary mar-b30">
          <h1 class>Predicted Income</h1>
          <div class="centerCenter textXxl mar-b30">{{ format.money(predictedIncome) }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="panel green mar-b30">
          <h1 class>
            Predicted Profit
            <span class="textSml textGrey">(after tax)</span>
          </h1>
          <div class="centerCenter textXxl mar-b30">{{ format.money(predictedProfit) }}</div>
        </div>
      </div>
      <div class="col-xs-12 hideMob">
        <div style="height: 400px; position: relative">
          <LineChart :styles="{ height: '400px' }" v-if="lineReady" :chartdata="line" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../utils/axios";
import format from "../utils/format";
import moment from "moment";
import LineChart from "../components/LineChart";
import Doughnut from "../components/Doughnut";
export default {
  name: "home",
  components: { LineChart, Doughnut },
  mounted() {
    this.getStats();
    this.getLineGraph();
  },
  data() {
    return {
      format,
      freeDays: 0,
      workedDays: 0,
      dueTasks: 0,
      completedTasks: 0,
      predictedProfit: 0,
      predictedIncome: 0,
      workingDaysLeft: 0,
      pending: {},
      lineGraphRange: "thisyear",
      lineReady: false,
      line: {
        datasets: [
          {
            label: "",
            data: [],
            borderColor: "",
          },
        ],
        labels: [],
      },
      options: {
        legend: { display: false },
        tooltips: { enabled: false },
      },
    };
  },
  methods: {
    getStats() {
      // get this months working days and show how many free days there are left
      axios.get("calendarentry?startDate=" + moment().startOf("month") + "&endDate=" + moment().endOf("month")).then((response) => {
        var entries = response.data;
        var date = moment();
        var freeDays = 0;
        while (date.month() === moment().month()) {
          if (date.day() !== 0 && date.day() !== 6) {
            var format = date.format("YYYY-MM-DD");
            var found = entries.some((e) => moment(e.date).format("YYYY-MM-DD") === format);
            if (!found) freeDays++;
          }
          date.add(1, "day");
        }
        this.freeDays = freeDays;

        this.workedDays = entries.filter((e) => moment(e.date) < moment()).length;
        this.workingDaysLeft = entries.filter((e) => moment(e.date) > moment()).length;
      });

      axios.get("invoice/pending").then((res) => {
        this.pending = res.data;
      });

      axios.get("transaction/profit?fromDate=" + moment().startOf("month").format("YYYY-MM-DD HH:mm:ss") + "&toDate=" + moment().endOf("month").format("YYYY-MM-DD HH:mm:ss")).then((response) => {
        this.predictedProfit = response.data.profit;
        this.predictedIncome = response.data.income;
      });
      axios.get("task/complete?completedStart=" + moment().startOf("month")).then((response) => (this.completedTasks = response.data.length));
      axios.get("task/due?dueStart=" + moment().startOf("month") + "&dueEnd=" + moment().endOf("month")).then((response) => (this.dueTasks = response.data.length));
    },
    getLineGraph() {
      var queryStr = "?";
      if (this.lineGraphRange) {
        switch (this.lineGraphRange) {
          case "thisyear":
            queryStr += "fromDate=" + moment().startOf("day").subtract(1, "year").format("YYYY-MM-DD HH:mm:ss");
            queryStr += "&toDate=" + moment().endOf("month").format("YYYY-MM-DD HH:mm:ss");
            break;
          case "lastyear":
            queryStr += "fromDate=" + moment().subtract(2, "year").format("YYYY-MM-DD HH:mm:ss");
            queryStr += "&toDate=" + moment().subtract(1, "year").endOf("month").format("YYYY-MM-DD HH:mm:ss");

            break;
        }
      }
      axios.get("transaction/linegraph" + queryStr).then((res) => {
        var graphData = {
          datasets: [
            {
              label: "Incoming",
              data: [],
              // fill: false,
              pointRadius: 0,
              lineTension: 0.3,
              backgroundColor: "rgb(79, 79, 151, 0.2)",
              borderColor: getComputedStyle(document.documentElement).getPropertyValue("--lightBlue"),
            },
            {
              label: "Outgoing",
              data: [],
              // fill: false,
              pointRadius: 0,
              lineTension: 0.3,
              backgroundColor: "rgb(248, 112, 96, 0.2)",
              borderColor: getComputedStyle(document.documentElement).getPropertyValue("--secondary"),
            },
          ],
          labels: [],
        };
        res.data.forEach((row) => {
          graphData.datasets[0].data.push(row.inc);
          graphData.datasets[1].data.push(Math.abs(row.out));
          graphData.labels.push(moment(row.month, "M").format("MMM"));
        });
        this.line = graphData;
        this.lineReady = true;
      });
    },
  },
};
</script>
