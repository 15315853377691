<template>
  <table class="mobiletable tasktable">
    <thead>
      <th>Title</th>
      <th style="text-align: center;">Type</th>
      <th>Due Date</th>
      <th>Status</th>
      <th>Time</th>
      <th style="text-align: center;">Priority</th>
      <th style="text-align: center; max-width: 100px">Tasks</th>
      <th style="max-width: 100px"></th>
    </thead>
    <tbody>
      <tr v-if="tasks && !tasks.length">
        <td colspan="99">
          <h1>No Tasks Added</h1>
        </td>
      </tr>
      <template v-for="(row, index) in tasks">
        <tr :key="'task-' + row.id">
          <td class="clickable" @click="() => expand(index)">{{row.name}}</td>
          <td align="center">
            <div v-if="row.type" class="chip" :class="typeclass(row.type)">{{formattype(row.type)}}</div>
          </td>
          <td align="center">{{$format.date(row.dueDate)}}</td>
          <td align="center">
            <div class="chip" :class="statusclass(row.status)">{{formatstatus(row.status)}}</div>
          </td>
          <td>{{$format.duration(row.actualTime || row.timeEstimate)}}</td>
          <td align="center">
            <div class="chip" :class="priorityclass(row.priority)">{{$format.humanizeText(row.priority)}}</div>
          </td>
          <td align="center">{{row.children}}</td>
          <td>
            <div class="quickFlex">
              <button class="xsmall mar-r5" @click="() => $emit('editTask', row)">edit</button>
              <button class="xsmall mar-r5 blue" @click="() => $router.push('/projects/' + row.ProjectId + '/tasks/' + row.id)">view</button>
            </div>
          </td>
        </tr>
        <template>
          <tr v-if="row.expanded && row.description" class="highlight">
            <td colspan="99">
              <div>{{row.description}}</div>
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["tasks"],
  methods: {
    expand(index) {
      let task = this.tasks[index];
      task.expanded = !task.expanded;
      this.$set(this.tasks, index, task);
    },
    formatstatus(status) {
      switch (status) {
        case "pending":
          return "Pending";
        case "in-progress":
          return "In-Progress";
        case "testing":
          return "Testing";
        case "deployed":
          return "Deployed";
        case "complete":
          return "Complete";
      }
    },
    formattype(type) {
      function camelize(str) {
        return str
          .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index == 0 ? word.toUpperCase() : word.toUpperCase();
          })
          .replace(/\s+/g, "");
      }
      switch (type) {
        case "research":
          return "R&D";
        default:
          return camelize(type);
      }
    },
    statusclass(status) {
      switch (status) {
        case "pending":
          return "grey";
        case "in-progress":
        case "testing":
          return "secondary";
        case "deployed":
        case "complete":
          return "primary";
      }

      return "grey";
    },
    priorityclass(priority) {
      switch (priority) {
        case "low":
          return "grey";
        case "medium":
          return "primary";
        case "high":
          return "purple";
        case "urgent":
          return "secondary";
      }
      return "grey";
    },
    typeclass(priority) {
      switch (priority) {
        case "development":
          return "green";
        case "bug":
          return "secondary";
        case "research":
        case "design":
          return "yellow";
        case "testing":
        case "deployment":
          return "primary";
      }
      return "grey";
    }
  }
};
</script>

<style>
</style>