<template>
  <div>
    <div class="quickFlex">
      <div v-if="id" class="exit" @click="() => $emit('onFinish')">
        <i class="fas fa-times"></i>
      </div>
      <div v-if="task" class="grow">
        <h1 class="textDarkWhite textXl">{{task.name}}</h1>
      </div>

      <div v-if="id" class="save right" @click="save()">
        <i class="fal fa-save"></i>
      </div>
    </div>
    <div class="row" v-if="task">
      <div class="col-xs-12 col-md-9">
        <div class="form-group">
          <textarea rows="8" v-model="task.description" class="white" />
        </div>
        <div class="mar-b30">
          <div class="verticalMiddle">
            <div class="textWhite">Documents ({{task.Documents.length}})</div>
            <button class="mar-l10 xsmall primary" @click="createDoc()">Add</button>
            <button class="mar-l10 xsmall grey" @click="() => hideDocs = !hideDocs">{{hideDocs ? 'Show' : 'Hide'}}</button>
          </div>
          <div class="documents" :class="{hide: hideDocs}">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-for="doc in task.Documents" :key="doc.id">
                <div class="document">
                  <div class="controls">
                    <i class="fal fa-pencil-alt edit" @click="editDocument(doc)"></i>
                  </div>
                  <div class="title">{{doc.name}}</div>
                  <div class="logo" v-if="!doc.image">
                    <i :class="getLogo(doc)"></i>
                  </div>
                  <div class="image" v-if="doc.image">{{doc.image}}</div>
                  <div class="link mar-b20">
                    <a :href="doc.link" class="button secondary xsmall" target="_blank">view</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="verticalMiddle">
          <div class="textWhite">Tasks ({{tasks.length}})</div>
          <button class="mar-l10 xsmall primary" @click="createTask()">Add</button>
          <button class="mar-l10 xsmall blue" @click="toggleComplete()">{{hidecomplete ? 'Show Complete' : 'Hide Complete'}}</button>
        </div>
        <task-table :tasks="tasks" @editTask="t => editTask = t" />
        <!-- content with description, tasks and docs -->
      </div>
      <div class="col-xs-12 col-md-3">
        <!-- details (due date, priority etc) -->
        <div class="row">
          <div class="col-xs-6 form-group">
            <label for>Status</label>
            <select v-model="task.status" class="white">
              <option value="pending">Pending</option>
              <option value="in-progress">In Progress</option>
              <option value="testing">In Progress</option>
              <option value="deployed">Deployed</option>
              <option value="complete">Complete</option>
            </select>
          </div>

          <div class="col-xs-6 form-group">
            <label for>Priority</label>
            <select v-model="task.priority" class="white">
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
              <option value="urgent">Urgent</option>
            </select>
          </div>

          <div class="col-xs-6 form-group">
            <label for>Time Estimate</label>
            <input type="number" class="white" v-model="task.timeEstimate" />
          </div>
          <div class="col-xs-6 form-group">
            <label for>Time Actual</label>
            <input type="number" class="white" v-model="task.actualTime" />
          </div>

          <div class="col-xs-6 form-group">
            <label for>Due Date</label>
            <datepicker :value="task.dueDate" wrapper-class="calendarwrapper" calendar-class="calendarclass" input-class="white"></datepicker>
          </div>

          <div class="col-xs-6">
            <button @click="save()" class="primary full-width small">Save</button>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 verticalMiddle mar-b20">
            <div class="textWhite textLg">Comments</div>
            <button class="tiny mar-l10" @click="() => addingComment = true">Add Comment</button>
          </div>
          <div class="col-xs-12 form-group" v-if="addingComment">
            <textarea rows="3" v-model="newComment" />
            <button class="primary small mar-t10 full-width" @click="saveComment()">Save</button>
          </div>
          <div class="col-xs-12">
            <div class="row">
              <div class="col-xs-12 mar-b20 borderBottomGrey pad-b10" v-for="comment in comments" :key="comment.id">
                <div class="textWhite mar-b5" style="line-height: 1.5">{{comment.text}}</div>
                <div class="textGrey textSml">{{$format.datetime(comment.createdAt)}} - {{comment.User.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Drawer :isOpen="document" :onClose="() => {}" :width="'500px'">
      <Document v-if="document" :data="document" :project="task.Project" :onFinish="closeDoc" :taskId="task.id" />
    </Drawer>

    <Drawer :isOpen="editTask" :onClose="() => {}" :width="'500px'">
      <Task v-if="editTask" :data="editTask" :project="task.Project" @onFinish="closeTask()" />
    </Drawer>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import format from "../../utils/format";
import Document from "../Projects/Document";
import Task from "../Projects/Task";
import TaskTable from "../../components/TasksTable";
import Datepicker from "vuejs-datepicker";
export default {
  props: ["id"],
  components: { Document, Datepicker, Task, TaskTable },
  data() {
    return {
      task: undefined,
      tasks: [],
      editTask: undefined,
      document: undefined,
      hideDocs: true,
      hidecomplete: true,
      comments: [],
      addingComment: false,
      newComment: ""
    };
  },
  created() {
    this.getTask();
  },
  watch: {
    "$route.params.taskId": function(val) {
      if (val !== this.task.id) {
        this.getTask();
      }
    }
  },
  methods: {
    getTask() {
      var url = "";
      if (this.$route.params.taskId) {
        url = "project/" + this.$route.params.id + "/task/" + this.$route.params.taskId;
      } else {
        url = "task/" + this.id;
      }
      axios.get(url).then(res => {
        this.task = res.data;
        this.getComments();
        this.getChildren();
      });
    },
    getChildren() {
      var queryStr = "?";
      if (this.hidecomplete) queryStr += "hidestatus=complete";
      axios.get("project/" + this.task.ProjectId + "/task/" + this.task.id + "/tasks" + queryStr).then(res => (this.tasks = res.data));
    },
    toggleComplete() {
      this.hidecomplete = !this.hidecomplete;
      this.getChildren();
    },
    save() {
      axios.put("project/" + this.task.ProjectId + "/task/" + this.task.id, this.task).then(() => {
        this.$toasted.show("Task Saved");
      });
    },
    createDoc() {
      this.document = {};
    },
    closeDoc() {
      this.document = undefined;
      axios.get("task/" + this.id || this.$route.params.id).then(res => (this.task.Documents = res.data.Documents));
    },
    createTask() {
      this.editTask = {
        parentId: this.task.id,
        ProjectId: this.task.ProjectId,
        ClientId: this.task.ClientId
      };
    },
    closeTask() {
      this.editTask = undefined;
      this.getChildren();
    },
    getLogo(doc) {
      switch (doc.type) {
        case "doc":
          return "far fa-file-word";
        case "img":
          return "far fa-file-image";
        case "vid":
          return "far fa-file-video";
        case "design":
          return "fal fa-pencil-ruler";
        case "pdf":
          return "far fa-file-pdf";
        case "mp3":
          return "fal fa-file-music";
        case "zip":
          return "far fa-file-archive";
        case "api":
          return "far fa-brackets-curly";
        default:
          return "far fa-file";
      }
    },
    getComments() {
      this.addingComment = false;
      this.newComment = "";
      axios.get("project/" + this.task.ProjectId + "/task/" + this.task.id + "/comment").then(res => (this.comments = res.data));
    },
    saveComment() {
      if (!this.newComment) return;
      axios
        .post("project/" + this.task.ProjectId + "/task/" + this.task.id + "/comment", {
          text: this.newComment
        })
        .then(this.getComments);
    }
  }
};
</script>

<style>
</style>
