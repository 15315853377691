<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1>{{project.id ? 'Update' : 'Create'}} Project</h1>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Name</label>
        <input type="text" v-model="project.name" class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Client</label>
        <select v-model="project.ClientId" class="white">
          <option v-for="client in clients" :key="client.id" :value="client.id">{{client.name}}</option>
        </select>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Status</label>
        <select v-model="project.status" class="white">
          <option value="Proposal">Proposal</option>
          <option value="Pending">Pending</option>
          <option value="Development">Development</option>
          <option value="Testing">Testing</option>
          <option value="Deployment">Deployment</option>
          <option value="Live">Live</option>
          <option value="Cancelled">Cancelled</option>
        </select>
      </div>
      <div :class="{'col-xs-6': !project.id, 'col-xs-4': project.id}">
        <button class="primary full-width mobilesmall" @click="save()">Save</button>
      </div>
      <div :class="{'col-xs-6': !project.id, 'col-xs-4': project.id}">
        <button class="full-width mobilesmall" @click="onFinish()">Cancel</button>
      </div>
      <div v-if="project.id" class="col-xs-4">
        <button class="secondary full-width mobilesmall" @click="deleteproject()">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
export default {
  props: ["data", "onFinish", "clients"],
  data() {
    return {
      project: {}
    };
  },
  mounted() {
    if (this.data) {
      this.project = JSON.parse(JSON.stringify(this.data));
    }
  },
  methods: {
    deleteproject() {
      axios.delete("project/" + this.project.id).then(this.onFinish);
    },
    save() {
      var promise;
      if (this.project.id) {
        promise = axios.put("project/" + this.project.id, this.project);
      } else {
        promise = axios.post("project", this.project, this.project);
      }
      promise.then(this.onFinish);
    }
  }
};
</script>

<style>
</style>