<template>
  <div>
    <div class="row">
      <div class="col-xs-6">
        <div class="exit" @click="() => $emit('onFinish')">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="save right" @click="saveAndExit()">
          <i class="fal fa-share-square"></i>
        </div>
        <div class="save right" @click="save()">
          <i class="fal fa-save"></i>
        </div>
      </div>
    </div>
    <div v-if="template">
      <div class="row">
        <div class="col-xs-12 col-md-6 template">
          <prism-editor v-model="template.html" :language="html"></prism-editor>
        </div>
        <div class="col-xs-12 col-md-6 template">
          <iframe style="width: 100%; height: 100%" :srcdoc="template.html"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrismEditor from "vue-prism-editor";
import axios from "../../../utils/axios";
export default {
  components: { PrismEditor },
  props: ["id", "type"],
  data() {
    return {
      templateId: this.id,
      template: undefined
    };
  },
  created() {
    if (this.id) {
      axios.get("template/" + this.id).then(response => (this.template = response.data));
    } else {
      axios.post("template", { html: "", json: {}, type: this.type }).then(response => {
        this.templateId = response.data.id;
        this.template = response.data;
      });
    }
  },
  methods: {
    save() {
      return axios.put("template/" + this.templateId, this.template);
    },
    saveAndExit() {
      this.save().then(() => this.$emit("onFinish"));
    }
  }
};
</script>

<style>
</style>