<template>
  <div id="app">
    <TopNav v-if="isAuthenticated()" @open="() => menuopen = true" />
    <div style="display: flex;" v-if="isAuthenticated()">
      <SideNav :open="sidemenuopen" />
      <div id="content" class="content">
        <!-- <div class="menu" @click="() => sidemenuopen = !sidemenuopen">
          <i class="far fa-bars"></i>
        </div>
        <div class="overlay" v-if="sidemenuopen"></div>-->
        <div>
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
    <router-view v-if="!isAuthenticated()" />

    <MobileNav :open="menuopen" @close="() => menuopen = false" />
  </div>
</template>

<script>
import TopNav from "./components/TopNav";
import SideNav from "./components/SideNav";
import MobileNav from "./components/MobileNav";
import auth from "./utils/auth";
export default {
  components: { TopNav, SideNav, MobileNav },
  data() {
    return {
      sidemenuopen: false,
      menuopen: false
    };
  },
  methods: {
    isAuthenticated() {
      return auth.isAuthenticated();
    }
  },
  mounted() {
    var user = localStorage.getItem("user");
    try {
      user = JSON.parse(user);
      if (user) {
        this.$store.commit("setUser", user);
      }
    } catch (e) {}
  },
  watch: {
    "$route.path": function() {
      this.menuopen = false;
    }
  }
};
</script>

<style>
@import "/css/flexboxgrid.min.css";
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
