<template>
  <div class="row">
    <div class="col-xs-12">
      <h1>Attachments</h1>
    </div>
    <div class="col-xs-12 mar-b30" v-if="!transaction.attachments || !transaction.attachments.length">
      <p class="textWhite">No Attachments</p>
    </div>
    <div class="col-xs-12 mar-b10" v-else v-for="(attachment, index) in transaction.attachments">
      <div class="quickFlex around textWhite borderBottomGrey mar-b5" style="padding-bottom: 5px;">
        <div>{{attachment.name}}</div>
        <div class="quickFlex">
          <div class="clickable mar-r5" @click="openAttachment(index)">
            <i class="far fa-cloud-download"></i>
          </div>
          <div class="clickable" @click="deleteAttachment(index)">
            <i class="fal fa-trash-alt"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 mar-b30">
      <button v-if="!emailImport" class="green small" @click="() => emailImport = true">Import from Email</button>
      <div v-else>
        <div class="form-group">
          <label for>Mailbox</label>
          <select v-model="mailbox" @change="getEmails()">
            <option :value="undefined">Select</option>
            <option v-for="mb in mailboxes" :value="mb">{{mb.user}}</option>
          </select>
        </div>
        <div v-if="emailLoaded && emails.length" class="form-group">
          <label>Email</label>
          <select v-model="email">
            <option :value="undefined">Select</option>
            <option v-for="em in emails" :value="em">{{em.from[0]}}: {{em.subject[0]}}</option>
          </select>
        </div>
        <div v-if="email">
          <button class="tiny primary" @click="uploadAttachment()">Select</button>
        </div>
        <div v-if="emailLoaded && !emails.length">
          <p class="textWhite">No Emails with PDF Attachments Found near this Transaction</p>
        </div>
      </div>
    </div>
    <div class="col-xs-12 mar-b30">
      <input type="file" ref="fileupload" style="display: none;" @change="upload" />
      <button class="green small" @click="$refs.fileupload.click()">Import File</button>
    </div>
    <div class="col-xs-12">
      <button class="small" @click="() => $emit('onFinish')">Close</button>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import download from "../../utils/download";
import moment from "moment";
export default {
  props: ["transaction"],
  data() {
    return {
      emailImport: false,
      mailboxes: [],
      mailbox: undefined,
      dateFrom: moment(this.transaction.datetime).subtract(1, "week"),
      dateTo: moment(this.transaction.datetime).add(1, "week"),
      emails: [],
      emailLoaded: false,
      email: undefined,
      file: undefined,
    };
  },
  created() {
    this.getMailboxes();
  },
  methods: {
    getMailboxes() {
      axios.get("mailbox").then((response) => {
        this.mailboxes = response.data;
      });
    },
    getEmails() {
      this.emails = [];
      this.emailLoaded = false;
      this.email = undefined;
      axios.get("mailbox/" + this.mailbox.id + "/messages?from=" + this.dateFrom.format("YYYY-MM-DD") + "&to=" + this.dateTo.format("YYYY-MM-DD")).then((response) => {
        this.emails = response.data;
        this.emailLoaded = true;
      });
    },
    uploadAttachment() {
      axios.get("mailbox/" + this.mailbox.id + "/messages/" + this.transaction.id + "/import?messageId=" + this.email["message-id"][0]).then((response) => {
        this.transaction.attachments = response.data;
        this.emailImport = false;
      });
    },
    deleteAttachment(index) {
      axios.delete("transaction/" + this.transaction.id + "/attachment?filename=" + (this.transaction.attachments[index].name || this.transaction.attachments[index])).then((response) => {
        this.transaction.attachments = response.data;
      });
    },
    openAttachment(index) {
      let file = this.transaction.attachments[index];
      window.open(file.path, "_blank");
    },
    upload(event) {
      console.log(event.target.files);
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      axios
        .post("transaction/" + this.transaction.id + "/attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$refs.fileupload.value = "";
          this.transaction.attachments = response.data;
        });
    },
  },
};
</script>

<style>
</style>