<template>
  <div class="showMob topnav" id="topnav">
    <div class="title">{{$format.humanizeText(title)}}</div>
    <div class="menu" @click="() => $emit('open')">
      <i class="fas fa-bars"></i>
    </div>
  </div>
</template>

<script>
import format from "../utils/format";
export default {
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.user && this.$store.state.user.role === "admin";
    },
    title() {
      switch (this.$route.name) {
        case "home":
          return "Endevrs";
        default:
          return this.$route.name;
      }
    }
  }
};
</script>

<style>
</style>