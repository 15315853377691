<template>
  <form @submit="save($event)">
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ task.id ? "Update" : "Create" }} Task</h1>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Title</label>
        <input ref="title" maxlength="255" v-model="task.name" class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Description</label>
        <textarea v-model="task.description" rows="4" class="white" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Task Type</label>
        <select v-model="task.type" class="white">
          <option value="design">Design</option>
          <option value="research">R&amp;D</option>
          <option value="development">Development</option>
          <option value="testing">Testing</option>
          <option value="deployment">Deployment</option>
          <option value="bug">Bug</option>
        </select>
      </div>
      <div class="col-xs-6 form-group">
        <label for>Time Estimate</label>
        <input type="number" class="white" v-model="task.timeEstimate" />
      </div>
      <div class="col-xs-6 form-group">
        <label for>Time Actual</label>
        <input type="number" class="white" v-model="task.actualTime" />
      </div>
      <div class="col-xs-12 form-group">
        <label for>Due Date</label>
        <datepicker v-model="task.dueDate" wrapper-class="calendarwrapper" :highlighted="{ dates: [new Date()] }" calendar-class="calendarclass" input-class="white"></datepicker>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Priority</label>
        <select v-model="task.priority" class="white">
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
          <option value="urgent">Urgent</option>
        </select>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Status</label>
        <select v-model="task.status" class="white">
          <option value="pending">Pending</option>
          <option value="in-progress">In Progress</option>
          <option value="testing">Testing</option>
          <option value="deployed">Deployed</option>
          <option value="complete">Complete</option>
        </select>
      </div>
      <div :class="{ 'col-xs-6': !task.id, 'col-xs-4': task.id }">
        <button class="primary full-width mobilesmall" type="submit">Save</button>
      </div>
      <div :class="{ 'col-xs-6': !task.id, 'col-xs-4': task.id }">
        <button class="full-width mobilesmall" type="button" @click="() => $emit('onFinish')">Cancel</button>
      </div>
      <div v-if="task.id" class="col-xs-4">
        <button class="secondary full-width mobilesmall" type="button" @click="deleteTask()">Delete</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "../../utils/axios";
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },
  props: ["data", "project"],
  data() {
    return {
      task: { status: "pending", priority: "low" },
    };
  },
  mounted() {
    if (this.data) {
      this.task = JSON.parse(JSON.stringify(this.data));
      this.task.ClientId = this.project.ClientId;
      if (!this.task.dueDate) this.task.dueDate = undefined;
      if (!this.task.type) this.task.type = "development";
      if (!this.task.status) this.task.status = "pending";
      if (!this.task.priority) this.task.priority = "low";
    }
    this.$refs.title.focus();
  },
  methods: {
    save(e) {
      e.preventDefault();
      if (this.task.status === "complete") {
        this.task.complete = true;
      } else {
        this.task.complete = false;
      }

      var promise;
      if (this.task.id) {
        promise = axios.put("project/" + this.project.id + "/task/" + this.task.id, this.task);
      } else {
        promise = axios.post("project/" + this.project.id + "/task", this.task);
      }

      promise.then(() => this.$emit("onFinish"));
    },
    deleteTask() {
      axios.delete("project/" + this.project.id + "/task/" + this.task.id).then(() => this.$emit("onFinish"));
    },
  },
};
</script>

<style>
</style>