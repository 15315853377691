<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <h1>{{entry.id ? 'Update' : 'Create'}} Transaction</h1>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Name</label>
        <input type="text" v-model="entry.from" class="white" />
      </div>
      <div class="col-xs-12 form-group" v-if="!entry.tideId">
        <label for>Amount</label>
        <input type="number" v-model="entry.amount" class="white" />
      </div>
      <div class="col-xs-12 form-group" v-if="!entry.tideId">
        <label for>Date</label>
        <datepicker :value="entry.createdAt" wrapper-class="calendarwrapper" @selected="date => entry.createdAt = date" calendar-class="calendarclass" input-class="white"></datepicker>
      </div>
      <div class="col-xs-12 form-group">
        <label for>Type</label>
        <select v-model="entry.type" class="white">
          <option value>--Select--</option>
          <option value="sale">Sale</option>
          <option value="salary">Salary</option>
          <option value="hosting">Hosting</option>
          <option value="equipment">Equipment</option>
          <option value="service">Service</option>
          <option value="bank fee">Bank Fee</option>
          <option value="insurance">Insurance</option>
        </select>
      </div>
      <div :class="{'col-xs-6': !entry.manual, 'col-xs-4': entry.manual}">
        <button class="primary full-width mobilesmall" @click="save()">Save</button>
      </div>
      <div :class="{'col-xs-6': !entry.manual, 'col-xs-4': entry.manual}">
        <button class="full-width mobilesmall" @click="onFinish()">Cancel</button>
      </div>
      <div v-if="entry.manual" class="col-xs-4">
        <button class="secondary full-width mobilesmall" @click="deleteentry()">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/axios";
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },
  props: ["data", "onFinish", "clients"],
  data() {
    return {
      entry: {}
    };
  },
  mounted() {
    if (this.data) {
      this.entry = JSON.parse(JSON.stringify(this.data));
      if (this.entry.amount < 0) {
        this.entry.amount = Math.abs(this.entry.amount);
      }
    }
  },
  methods: {
    deleteentry() {
      axios.delete("transaction/" + this.entry.id).then(this.onFinish);
    },
    save() {
      var promise;
      if (this.entry.amount > 0) {
        this.entry.amount = 0 - this.entry.amount;
      }
      if (this.entry.id) {
        promise = axios.put("transaction/" + this.entry.id, this.entry);
      } else {
        promise = axios.post("transaction", this.entry, this.entry);
      }
      promise.then(this.onFinish);
    }
  }
};
</script>

<style>
</style>