<template>
  <div class="panel bankaccount">
    <div class="row center-xs">
      <div class="col-xs-12 textLg">{{ account.currency }}</div>
      <div class="col-xs-12 mar-t20 mar-b30 quickFlex center">
        <i class="card" :class="getCryptoIcon(account.currency)"></i>
      </div>
      <div v-if="['EUR', 'USD', 'GBP'].indexOf(account.currency) > -1" class="col-xs-12 textMed mar-b20">{{ $format.money(account.value) }}</div>
      <div v-else class="col-xs-12 mar-b20 mar-t30">
        <div class="row">
          <div class="col-xs-6 end-xs mar-b30">{{ $format.money(account.rate) }}</div>
          <div class="col-xs-6 start-xs mar-b30" :class="{ textPrimary: account.previous < account.rate, textSecondary: account.previous > account.rate }">
            <i class="fas fa-level-up" :class="{ 'fa-level-up': account.previous < account.rate, 'fa-level-down': account.previous > account.rate }"></i>
            {{ $format.percentInc(account.previous, account.rate, 2) }}
          </div>
          <div class="col-xs-6 end-xs">{{ account.available }}</div>
          <div class="col-xs-6 start-xs">{{ $format.money(account.value) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    account: Object,
  },
  methods: {
    getCryptoIcon(currency) {
      switch (currency) {
        case "BTC":
          return "fab fa-bitcoin";
        case "ETH":
          return "fab fa-ethereum";
        case "GBP":
          return "far fa-pound-sign";
        case "EUR":
          return "fas fa-euro-sign";
        case "USB":
          return "far fa-dollar-sign";
      }
      return "fas fa-question";
    },
  },
};
</script>

<style>
</style>